import React from "react";
import { CheckBox } from "../../../component/CheckBox/CheckBox";
import { SvgCollection } from "../../../component/Image/Image";

export const CardsList = ({ Page, setPage, List, setList }) => {
  return (
    <div className="cards_list_container">
      <div className="cards_list">
        {List?.map((el, i) => (
          <div
            key={i}
            className="cards_box"
            onClick={() => {
              setList((prev) => [
                ...prev.map((data, i1) =>
                  i == i1
                    ? { ...data, active: !data.active }
                    : { ...data, active: false }
                ),
              ]);
            }}
          >
            <div>
              <CheckBox
                {...{
                  check: el.active,
                  onclick: () => {},
                }}
              />
            </div>
            <div className="cards_box_detail">
              <div>{el.bankname}</div>
              <div className="cards_detail_nbn">
                <div>{el.number}</div>
                <div>
                  <SvgCollection img_var={el.brand} />
                </div>
                <div>| {el?.name}</div>
              </div>
            </div>
            <div className="cards_box_bank">
              <SvgCollection img_var={"bank"} />
            </div>
          </div>
        ))}
      </div>
      <div
        className="cards_add"
        onClick={() => {
          setPage("addcard");
        }}
      >
        Add another card
        <div>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_214_2184)">
              <path
                d="M15.1999 7.2H8.80005V0.79995C8.80005 0.358462 8.44159 0 7.99995 0C7.55846 0 7.2 0.358462 7.2 0.79995V7.2H0.79995C0.358462 7.2 0 7.55846 0 7.99995C0 8.44159 0.358462 8.80005 0.79995 8.80005H7.2V15.1999C7.2 15.6416 7.55846 16 7.99995 16C8.44159 16 8.80005 15.6416 8.80005 15.1999V8.80005H15.1999C15.6416 8.80005 16 8.44159 16 7.99995C16 7.55846 15.6416 7.2 15.1999 7.2Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_214_2184">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
};
