import React, { useEffect } from "react";
import { Inputs } from "../../../component/Input/Inputs";
import { SelectInput } from "../../../component/Input/SelectInput";
import { useNavigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { IndividualProfile, token } from "../../../component/Api/api";
import { date_formatter } from "../../../utils/utils";
import { useSelector } from "react-redux";

export const IndiProfile = ({
  Page,
  setPage,
  pagevalues,
  setpagevalues,
  EditProfile,
  formDataImage,
  setEditProfile,
  errpagevalues,
  seterrpagevalues,
  setloading,
}) => {
  const nav = useNavigate();
  const current_store = useSelector((state) => state.checktoken_data);
  function parseDate(dateString) {
    const [year, month, day] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  }
  useEffect(() => {
    setpagevalues((prev) => ({
      ...prev,
      birthdate: parseDate(prev.dob) || prev.birthdate,
      paroleProbationdummy: prev.paroleProbation ? "Yes" : "No",
      veterandummy: prev.veteran ? "Yes" : "No",
      criminalBackgrounddummy: prev.criminalBackground ? "Yes" : "No",
      registeredToVotedummy: prev.registeredToVote ? "Yes" : "No",
      pregnantOrIncarcerateddummy: prev.pregnantOrIncarcerated ? "Yes" : "No",
      solitaryConfinementdummy: prev.solitaryConfinement ? "Yes" : "No",
      showAllDataDummy: prev.showAllData ? "Public" : "Private",
      solitaryConfinementExtra: {
        duration: prev?.solitaryConfinementExtra?.duration || "",
        number: prev?.solitaryConfinementExtra?.number || "",
      },
      veteranPlace: prev?.veteranPlace || "",
    }));
  }, []);

  return (
    <div className="profile_ind_section">
      <div className="uprofile_photo">
        <div
          className="user_profile_img"
          style={{
            backgroundImage: `url(${
              pagevalues?.picture ||
              "https://access-files.sfo2.digitaloceanspaces.com/images/17216567670051721656767001.webp"
            })`,
          }}
        ></div>
        {EditProfile ? (
          <div className="user_profile_svg">
            {[
              {
                name: "Camera",
                type: "image",
                first_color: "#B758E6",
                last_color: "#C562FB",
                multiSelect: false,
                capture: true,

                input: {
                  valids: "",
                },
                input_filter: ["image/*"],
                click: () => {},
              },
            ].map((el, i) => (
              <label key={i} className="img_label" htmlFor={el.name}>
                <input
                  type="file"
                  id={el.name}
                  capture={el.capture}
                  {...{
                    ...(el.input_filter.length
                      ? { accept: el.input_filter }
                      : {}),
                  }}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                  onChange={(e) => {
                    formDataImage(e);
                  }}
                  multiple={el.multiSelect}
                />
                <div>
                  <svg
                    width="23"
                    height="19"
                    viewBox="0 0 23 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.5802 10.7201C14.5802 12.6725 12.992 14.2608 11.0396 14.2608C9.08715 14.2608 7.49949 12.6725 7.49949 10.7201C7.49949 8.76768 9.08715 7.17942 11.0396 7.17942C12.992 7.17942 14.5802 8.76829 14.5802 10.7201ZM22.0166 5.69251V15.7489C22.0166 16.3927 21.7608 17.0102 21.3056 17.4655C20.8503 17.9207 20.2328 18.1765 19.589 18.1765H2.49011C1.84627 18.1765 1.2288 17.9207 0.773531 17.4655C0.318266 17.0102 0.0625 16.3927 0.0625 15.7489V5.69251C0.0625 5.04867 0.318266 4.43119 0.773531 3.97593C1.2288 3.52066 1.84627 3.2649 2.49011 3.2649H5.47608V2.42494C5.47608 1.86158 5.69987 1.32129 6.09823 0.922933C6.49659 0.524576 7.03687 0.300781 7.60024 0.300781H14.4789C15.0422 0.300781 15.5825 0.524576 15.9809 0.922933C16.3792 1.32129 16.603 1.86158 16.603 2.42494V3.26429H19.589C20.9296 3.2649 22.0166 4.35186 22.0166 5.69251ZM16.4009 10.7201C16.4009 7.76387 13.9958 5.35871 11.0396 5.35871C8.08394 5.35871 5.67878 7.76387 5.67878 10.7201C5.67878 13.6763 8.08394 16.0815 11.0396 16.0815C13.9958 16.0815 16.4009 13.6763 16.4009 10.7201Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </label>
            ))}
          </div>
        ) : (
          ""
        )}
      </div>
      {EditProfile ? (
        ""
      ) : (
        <>
          <div className="uprofile_name">{pagevalues?.name || "Xyz"}</div>
          <div className="uprofile_type">{pagevalues?.type || "Type"}</div>
        </>
      )}
      {EditProfile ? (
        <div className="uprofile_inputs_list">
          <div>
            <Inputs
              {...{
                inpType: "type1",
                attr: {
                  class: "",
                  readonly: "",
                  value: pagevalues.name,
                  id: "name",
                  error: errpagevalues?.name,
                  type: "text",
                  event: {
                    change: ({ target }) => {
                      setpagevalues((prev) => ({
                        ...prev,
                        name: target.value,
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        name: !target.value,
                      }));
                    },
                  },
                },
                data: {
                  title: "Name",
                  prefix: "",
                  type: "",
                },
              }}
            />
          </div>
          <div>
            <Inputs
              {...{
                inpType: "type1",
                attr: {
                  class: "",
                  readonly: "",
                  value: pagevalues.phone,
                  id: "phone",
                  error: errpagevalues?.phone,
                  type: "text",
                  event: {
                    change: ({ target }) => {
                      if (!/^\d*$/i.test(target.value)) {
                        return;
                      }
                      const cleanedInput = target.value;
                      if (cleanedInput.length > 10) {
                        return;
                      }
                      setpagevalues((prev) => ({
                        ...prev,
                        phone: cleanedInput,
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        phone: cleanedInput.length != 10,
                      }));
                    },
                  },
                },
                data: {
                  title: "Phone",
                  prefix: "",
                  type: "",
                },
              }}
            />
          </div>
          <div>
            <Inputs
              {...{
                inpType: "type1",
                attr: {
                  class: "",
                  readonly: false,
                  value: pagevalues.gender,
                  id: "gender",
                  error: errpagevalues?.gender,
                  type: "text",
                  event: {
                    change: ({ target }) => {
                      setpagevalues((prev) => ({
                        ...prev,
                        gender: target.value,
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        gender: !target.value,
                      }));
                    },
                  },
                },
                data: {
                  title: "Gender",
                  prefix: "",
                  type: "",
                },
              }}
            />
          </div>
          <div>
            <ThemeProvider
              theme={createTheme({
                palette: {
                  mode: "dark",
                },
              })}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  variant="standard"
                  sx={{
                    width: "100%",
                    color: "white !important",
                    "& .Mui-error .MuiOutlinedInput-notchedOutline": {
                      borderColor: "var(--red) !important",
                      outline: "0px !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(255, 255, 255, 0.20) !important",
                      borderWidth: "1px !important",
                      color: "white !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(255, 255, 255, 0.20) !important",
                      borderRadius: "8px !important",
                      borderWidth: "1px !important",
                    },
                  }}
                  disableFuture
                  className={"cust_input"}
                  value={
                    pagevalues.birthdate ? dayjs(pagevalues.birthdate) : null
                  }
                  onChange={(value) => {
                    setpagevalues((prev) => ({
                      ...prev,
                      birthdate: value,
                    }));
                  }}
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
            </ThemeProvider>
          </div>
          <div>
            <Inputs
              {...{
                inpType: "type1",
                attr: {
                  class: "",
                  readonly: false,
                  value: pagevalues.city,
                  id: "city",
                  error: errpagevalues?.city,
                  type: "text",
                  event: {
                    change: ({ target }) => {
                      setpagevalues((prev) => ({
                        ...prev,
                        city: target.value,
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        city: !target.value,
                      }));
                    },
                  },
                },
                data: {
                  title: "City",
                  prefix: "",
                  type: "",
                },
              }}
            />
          </div>
          <div>
            <Inputs
              {...{
                inpType: "type1",
                attr: {
                  class: "",
                  readonly: false,
                  value: pagevalues.state,
                  id: "state",
                  error: errpagevalues?.state,
                  type: "text",
                  event: {
                    change: ({ target }) => {
                      setpagevalues((prev) => ({
                        ...prev,
                        state: target.value,
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        state: !target.value,
                      }));
                    },
                  },
                },
                data: {
                  title: "State",
                  prefix: "",
                  type: "",
                },
              }}
            />
          </div>
          <div>
            <Inputs
              {...{
                inpType: "type1",
                attr: {
                  class: "",
                  readonly: false,
                  value: pagevalues.zipcode,
                  id: "zipcode",
                  error: errpagevalues?.zipcode,
                  type: "text",
                  event: {
                    change: ({ target }) => {
                      if (!/^\d*$/i.test(target.value)) {
                        return;
                      }
                      setpagevalues((prev) => ({
                        ...prev,
                        zipcode: target.value,
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        zipcode: !target.value || target.value.length > 6,
                      }));
                    },
                  },
                },
                data: {
                  title: "Zip code",
                  prefix: "",
                  type: "",
                },
              }}
            />
          </div>
          <div>
            Is Your Profile Public or Private?
            <div className="user_btn_list">
              {["Public", "Private"].map((el, i) => (
                <div
                  className={
                    "user_btn" +
                    (pagevalues.showAllDataDummy == el ? " active_btn" : "")
                  }
                  key={i}
                  onClick={() => {
                    setpagevalues((prev) => ({
                      ...prev,
                      showAllDataDummy: el,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      showAllDataDummy: !el,
                    }));
                  }}
                >
                  {el}
                </div>
              ))}
            </div>
            <div
              className={
                "user_error" + (errpagevalues.showAllDataDummy ? " active" : "")
              }
            >
              Select any one
            </div>
          </div>
          <div>
            History of criminal background?
            <div className="user_btn_list">
              {["Yes", "No"].map((el, i) => (
                <div
                  className={
                    "user_btn" +
                    (pagevalues.criminalBackgrounddummy == el
                      ? " active_btn"
                      : "")
                  }
                  key={i}
                  onClick={() => {
                    setpagevalues((prev) => ({
                      ...prev,
                      criminalBackgrounddummy: el,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      criminalBackgrounddummy: !el,
                    }));
                  }}
                >
                  {el}
                </div>
              ))}
            </div>
            <div
              className={
                "user_error" +
                (errpagevalues.criminalBackgrounddummy ? " active" : "")
              }
            >
              Select any one
            </div>
          </div>
          <div>
            Are you registered to vote?
            <div className="user_btn_list">
              {["Yes", "No"].map((el, i) => (
                <div
                  className={
                    "user_btn" +
                    (pagevalues.registeredToVotedummy == el
                      ? " active_btn"
                      : "")
                  }
                  key={i}
                  onClick={() => {
                    setpagevalues((prev) => ({
                      ...prev,
                      registeredToVotedummy: el,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      registeredToVotedummy: !el,
                    }));
                  }}
                >
                  {el}
                </div>
              ))}
            </div>
            <div
              className={
                "user_error" +
                (errpagevalues.registeredToVotedummy ? " active" : "")
              }
            >
              Select any one
            </div>
          </div>
          <div>
            Have you been pregnant or incarcerated?
            <div className="user_btn_list">
              {["Yes", "No"].map((el, i) => (
                <div
                  className={
                    "user_btn" +
                    (pagevalues.pregnantOrIncarcerateddummy == el
                      ? " active_btn"
                      : "")
                  }
                  key={i}
                  onClick={() => {
                    setpagevalues((prev) => ({
                      ...prev,
                      pregnantOrIncarcerateddummy: el,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      pregnantOrIncarcerateddummy: !el,
                    }));
                  }}
                >
                  {el}
                </div>
              ))}
            </div>
            <div
              className={
                "user_error" +
                (errpagevalues.pregnantOrIncarcerateddummy ? " active" : "")
              }
            >
              Select any one
            </div>
          </div>

          <div>
            Are you currently on parole/probation?*
            <div className="user_btn_list">
              {["Yes", "No"].map((el, i) => (
                <div
                  className={
                    "user_btn" +
                    (pagevalues.paroleProbationdummy == el ? " active_btn" : "")
                  }
                  key={i}
                  onClick={() => {
                    setpagevalues((prev) => ({
                      ...prev,
                      paroleProbationdummy: el,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      paroleProbationdummy: !el,
                    }));
                  }}
                >
                  {el}
                </div>
              ))}
            </div>
            <div
              className={
                "user_error" +
                (errpagevalues.paroleProbationdummy ? " active" : "")
              }
            >
              Select any one
            </div>
          </div>
          <div>
            Have you ever been in solitary confinement?
            <div className="user_btn_list">
              {["Yes", "No"].map((el, i) => (
                <div
                  className={
                    "user_btn" +
                    (pagevalues.solitaryConfinementdummy == el
                      ? " active_btn"
                      : "")
                  }
                  key={i}
                  onClick={() => {
                    setpagevalues((prev) => ({
                      ...prev,
                      solitaryConfinementdummy: el,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      solitaryConfinementdummy: !el,
                    }));
                  }}
                >
                  {el}
                </div>
              ))}
            </div>
            <div
              className={
                "user_error" +
                (errpagevalues.solitaryConfinementdummy ? " active" : "")
              }
            >
              Select any one
            </div>
          </div>
          <div
            style={
              pagevalues.solitaryConfinementdummy == "Yes"
                ? {
                    // height: 94,
                    // height: dayssolitary?.current?.clientHeight + 2,
                    // padding: "0 1px",
                    // overflow: "unset",
                    display: "block",
                  }
                : {
                    // height: 0,
                    // padding: "0",
                    // overflow: "hidden",
                    display: "none",
                  }
            }
          >
            <div>
              Enter the amount of time you have spent in confinement*
              <div className="solidatery_yes">
                <div className="user_btn_list">
                  <Inputs
                    {...{
                      inpType: "type1",
                      attr: {
                        class: "",
                        readonly: "",
                        type: "text",
                        value: pagevalues?.solitaryConfinementExtra?.number,
                        error: errpagevalues?.solitaryConfinementExtra?.number,
                        event: {
                          change: ({ target }) => {
                            if (!/^\d*$/i.test(target.value)) {
                              return;
                            }
                            setpagevalues((prev) => ({
                              ...prev,
                              solitaryConfinementExtra: {
                                ...prev.solitaryConfinementExtra,
                                number: target.value,
                              },
                            }));
                            seterrpagevalues((prev) => ({
                              ...prev,
                              solitaryConfinementExtra: {
                                ...prev.solitaryConfinementExtra,
                                number: !target.value,
                              },
                            }));
                          },
                        },
                      },
                      data: {
                        title: "",
                        placeholder: "Ex: 1",
                        prefix: "",
                      },
                    }}
                  />
                </div>
                <div className="user_btn_list">
                  <SelectInput
                    {...{
                      inptype: "type1",
                      attr: {
                        class: "",
                        type: "",
                        value: pagevalues?.solitaryConfinementExtra?.duration,
                        error:
                          errpagevalues?.solitaryConfinementExtra?.duration,
                        event: {
                          click: (value) => {
                            setpagevalues((prev) => ({
                              ...prev,
                              solitaryConfinementExtra: {
                                ...prev.solitaryConfinementExtra,
                                duration: value,
                              },
                            }));
                            seterrpagevalues((prev) => ({
                              ...prev,
                              solitaryConfinementExtra: {
                                ...prev.solitaryConfinementExtra,
                                duration: !value,
                              },
                            }));
                          },
                        },
                      },
                      data: {
                        type: "single",
                        placeholder: "Ex: Month",
                        list: ["Days", "Months", "Years"],
                        bottomnav: false,
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            Are you a veteran?
            <div className="user_btn_list">
              {["Yes", "No"].map((el, i) => (
                <div
                  className={
                    "user_btn" +
                    (pagevalues.veterandummy == el ? " active_btn" : "")
                  }
                  key={i}
                  onClick={() => {
                    setpagevalues((prev) => ({
                      ...prev,
                      veterandummy: el,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      veterandummy: !el,
                    }));
                  }}
                >
                  {el}
                </div>
              ))}
            </div>
            <div
              className={
                "user_error" + (errpagevalues.veterandummy ? " active" : "")
              }
            >
              Select any one
            </div>
          </div>
          <div
            style={
              pagevalues.veterandummy == "Yes"
                ? {
                    // height: branchname?.current?.clientHeight + 2,
                    // padding: "0 1px",
                    // overflow: "hidden",
                    display: "block",
                  }
                : {
                    // height: 0,
                    // padding: "0",
                    // overflow: "hidden",
                    display: "none",
                  }
            }
          >
            <div>
              What branch were you a member of?*
              <div className="user_btn_list">
                <Inputs
                  {...{
                    inpType: "type1",
                    attr: {
                      class: "",
                      readonly: "",
                      type: "text",
                      value: pagevalues.veteranPlace,
                      error: errpagevalues?.veteranPlace,
                      event: {
                        change: ({ target }) => {
                          setpagevalues((prev) => ({
                            ...prev,
                            veteranPlace: target.value,
                          }));
                          seterrpagevalues((prev) => ({
                            ...prev,
                            veteranPlace: !target.value,
                          }));
                        },
                      },
                    },
                    data: {
                      title: "",
                      placeholder: "Enter branch name",
                      prefix: "",
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="service_seeking_individual_profile">
              Services seeking
            </div>
            <SelectInput
              {...{
                inptype: "type1",
                attr: {
                  class: "",
                  type: "",
                  value: pagevalues.servicesSeeking,
                  error: errpagevalues.servicesSeeking,
                  event: {
                    click: (value) => {
                      setpagevalues((prev) => ({
                        ...prev,
                        servicesSeeking: prev.servicesSeeking.includes(value)
                          ? prev.servicesSeeking.filter((el) => el != value)
                          : [...prev.servicesSeeking, value],
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        servicesSeeking: false,
                      }));
                    },
                  },
                },
                data: {
                  type: "",
                  placeholder: "Ex: Food",
                  list: [
                    "Employment",
                    "Housing",
                    "Food",
                    "Clothing",
                    "Mental Health",
                    "Health/Wellness",
                    "Healthcare",
                    "Reproductive Health",
                    "Skills Training",
                    "Legal Services",
                    "Childcare",
                    "Diapers",
                    "Baby Formula",
                    "Feminine Hygiene Products",
                    "Higher Education",
                    "Support Groups",
                    "Utility Assistance",
                    "Furniture",
                    "Hygiene Products",
                    "Dentist",
                    "Identification",
                    "Transportation",
                    "Financial Services",
                    "Literacy",
                    "Insurance",
                    "Reentry Program",
                    "Voting Rights",
                    "Probation Reform GA",
                    "Medical",
                    "Carpentry",
                  ],
                  bottomnav: true,
                },
              }}
            />
          </div>
          <div>
            Is there anything else you want to share about your lived
            experience?
            <div className="user_btn_list">
              <Inputs
                {...{
                  inpType: "type1",
                  attr: {
                    class: "",
                    id: "indilifeExperience",
                    readonly: "",
                    type: "text",
                    value: pagevalues.lifeExperience,
                    error: errpagevalues?.lifeExperience,
                    event: {
                      change: ({ target }) => {
                        setpagevalues((prev) => ({
                          ...prev,
                          lifeExperience: target.value,
                        }));
                        seterrpagevalues((prev) => ({
                          ...prev,
                          lifeExperience: !target.value,
                        }));
                      },
                    },
                  },
                  data: {
                    title: "",
                    prefix: "",
                    type: "textarea",
                  },
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="uprofile_detail">
          <div>
            <div>Individual Name</div>
            <div>{pagevalues?.name || "-"}</div>
          </div>
          <div>
            <div>Phone</div>
            <div>{pagevalues?.phone || "-"}</div>
          </div>
          <div>
            <div>Email</div>
            <div>{pagevalues?.email || "-"}</div>
          </div>
          <div>
            <div>Gender</div>
            <div>{pagevalues?.gender || "-"}</div>
          </div>
          <div>
            <div>Birthdate</div>
            <div>
              {date_formatter(
                parseDate(pagevalues?.dob),
                "date/month/year_long"
              ) || "-"}
            </div>
          </div>
          <div>
            <div>City</div>
            <div>{pagevalues?.city || "-"}</div>
          </div>
          <div>
            <div>State</div>
            <div>{pagevalues?.state || "-"}</div>
          </div>
          <div>
            <div>Zipcode</div>
            <div>{pagevalues?.zipcode || "-"}</div>
          </div>

          <div>
            <div>Is your profile public or private?</div>
            <div>{pagevalues?.showAllData ? "Public" : "Private"}</div>
          </div>
          <div>
            <div>Do you have a criminal background?</div>
            <div>{pagevalues?.criminalBackground ? "Yes" : "No"}</div>
          </div>
          <div>
            <div>Are you currently on parole/probation?</div>
            <div>{pagevalues?.paroleProbation ? "Yes" : "No"}</div>
          </div>
          <div>
            <div>Are you registered to vote?</div>
            <div>{pagevalues?.registeredToVote ? "Yes" : "No"}</div>
          </div>
          <div>
            <div>Have you been pregnant or incarcerated?</div>
            <div>{pagevalues?.pregnantOrIncarcerated ? "Yes" : "No"}</div>
          </div>
          <div>
            <div>Have you ever been in solitary confinement?</div>
            <div>{pagevalues?.solitaryConfinement ? "Yes" : "No"}</div>
          </div>
          {pagevalues?.solitaryConfinement ? (
            <div>
              <div>Enter the amount of time you have spent in confinement</div>
              {pagevalues?.solitaryConfinementExtra?.number ? (
                <div>
                  {pagevalues?.solitaryConfinementExtra?.number +
                    " " +
                    pagevalues?.solitaryConfinementExtra?.duration}
                </div>
              ) : (
                "-"
              )}
            </div>
          ) : (
            ""
          )}

          <div>
            <div>Are you a veteran?</div>
            <div>{pagevalues?.veteran ? "Yes" : "No"}</div>
          </div>
          {pagevalues?.veteran ? (
            <div>
              <div>What branch were you a member of?</div>
              <div>{pagevalues?.veteranPlace || "-"}</div>
            </div>
          ) : (
            ""
          )}
          <div>
            <div>Services seeking</div>
            <div className="servicesoffered_list">
              {pagevalues?.servicesSeeking?.map((el, i) => (
                <div key={i}>{el}</div>
              ))}
            </div>
          </div>
          <div>
            <div>
              {" "}
              Is there anything else you want to share about your lived
              experience?
            </div>
            <div>{pagevalues?.lifeExperience || "-"}</div>
          </div>
        </div>
      )}
    </div>
  );
};
