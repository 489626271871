import React from "react";

export const RTE_Icons = ({ icon, active }) => {
  switch (icon) {
    case "bold":
      return (
        <div className={active ? "active" : ""}>
          {/* <BoldIcon
          style={{
            "--current_svg_color": !active ? "var(--modal_bg)" : "var(--black)",
          }}
          /> */}
          B
        </div>
      );
    case "italic":
      return (
        <div className={active ? "active" : ""} style={{ fontStyle: "italic" }}>
          I
        </div>
      );
    case "underline":
      return (
        <div
          className={active ? "active" : ""}
          style={{ textDecoration: "underline" }}
        >
          U
        </div>
      );
    case "code":
      return <div className={active ? "active" : ""}>{"</>"}</div>;
    case "h1":
      return <div className={active ? "active" : ""}>H1</div>;
    case "h2":
      return <div className={active ? "active" : ""}>H2</div>;
    case "h3":
      return <div className={active ? "active" : ""}>H3</div>;
    case "h4":
      return <div className={active ? "active" : ""}>H4</div>;
    case "h5":
      return <div className={active ? "active" : ""}>H5</div>;
    case "h6":
      return <div className={active ? "active" : ""}>H6</div>;

    default:
      break;
  }
  return <div>RTE_Icons</div>;
};
