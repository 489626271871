import React from "react";
import { OrgProfile } from "./OrgProfile";
import { IndiProfile } from "./IndiProfile";
import { SelectOrg } from "./SelectOrg";
import { CardsList } from "../Card/CardsList";
import { AddCards } from "../Card/AddCards";
import { SelectOrgView } from "./SelectOrgView";

export const UProfileAllPages = ({ Page, setPage, otherSets }) => {
  const data = {
    organization: (
      <OrgProfile
        {...{
          Page,
          setPage,
          ...otherSets,
        }}
      />
    ),
    individual: (
      <IndiProfile
        {...{
          Page,
          setPage,
          ...otherSets,
        }}
      />
    ),
    selectOrg: (
      <SelectOrg
        {...{
          Page,
          setPage,
          ...otherSets,
        }}
      />
    ),
    selectOrgView: (
      <SelectOrgView
        {...{
          Page,
          setPage,
          ...otherSets,
        }}
      />
    ),
    cardList: (
      <CardsList
        {...{
          Page,
          setPage,
          ...otherSets,
        }}
      />
    ),
    addcard: (
      <AddCards
        {...{
          Page,
          setPage,
          ...otherSets,
        }}
      />
    ),
  };
  return data[Page];
};
