import React, { useEffect } from "react";
import { Inputs } from "../../../component/Input/Inputs";
import { CurrentSection } from "../../../component/AnalyticsComp/CurrentSection";
import { ItemCard } from "../../../component/Card/ItemCard";
import { scrollToTop } from "../../../utils/utils";

export const OrganizationList = ({
  FilterList,
  Calfa,
  FilterObj,
  setPages,
  FunctionFilter,
  ViewData,
  setViewData,
  Search,
  setSearch,
  setMaintainObj,
}) => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="organization_container">
      <div className="organization_top">
        <Inputs
          {...{
            inpType: "type1",
            attr: {
              class: "search",
              readonly: "",
              value: Search,
              id: "search",
              type: "text",
              event: {
                change: ({ target }) => {
                  if (
                    /^[a-zA-Z0-9\s#@]+$/.test(target.value) ||
                    target.value == ""
                  ) {
                    const data = FilterObj["Profile created"]
                      ? FilterObj["Profile created"][0]
                      : "";
                    FunctionFilter(target.value, data);
                    setSearch(target.value);
                  }
                },
              },
            },
            data: {
              title: "Search",
              prefix: (
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1_6693)">
                    <path
                      d="M17.8292 17.0207L13.1819 12.4477C14.3989 11.1255 15.1466 9.37682 15.1466 7.45261C15.146 3.33639 11.7556 0 7.57302 0C3.39042 0 0 3.33639 0 7.45261C0 11.5688 3.39042 14.9052 7.57302 14.9052C9.3802 14.9052 11.0377 14.2801 12.3397 13.241L17.005 17.832C17.2323 18.056 17.6014 18.056 17.8287 17.832C17.8827 17.7792 17.9257 17.7162 17.9551 17.6465C17.9844 17.5769 17.9996 17.5021 17.9996 17.4265C17.9997 17.3509 17.9846 17.2761 17.9554 17.2064C17.9261 17.1367 17.8832 17.0736 17.8292 17.0207ZM7.57302 13.7586C4.03408 13.7586 1.16522 10.9353 1.16522 7.45261C1.16522 3.96991 4.03408 1.14663 7.57302 1.14663C11.112 1.14663 13.9808 3.96991 13.9808 7.45261C13.9808 10.9353 11.112 13.7586 7.57302 13.7586Z"
                      fill="#D0D0D1"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_6693">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              ),
              type: "",
            },
          }}
        />
        <CurrentSection
          {...{
            alfa: FilterList.length
              ? Calfa?.charAt(0)?.toUpperCase() ||
                FilterList[0]?.name?.charAt(0)?.toUpperCase()
              : "",
            List: FilterList,
          }}
        />
      </div>
      {FilterList.length == 0 ? (
        <div className="organization_nodata">
          <div className="p_svg">
            <svg
              width="50"
              height="50"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1_5324)">
                <path
                  d="M15.458 25.1566V9.95841L25.1705 9.94707V26.4562L27 25.4995L27.0295 9.01597C27.0295 8.5086 26.6056 8.08593 26.0989 8.08593L14.5274 8.09944C14.0145 8.10006 13.599 8.51622 13.599 9.02948V25.1566H3.82949V6.75298L13.599 4.12482V6.23631H15.458V2.91172C15.458 2.31003 14.8686 1.85719 14.2871 2.01359L2.65864 5.14181C2.46114 5.195 2.28668 5.31182 2.16228 5.47419C2.03788 5.63655 1.97048 5.8354 1.97051 6.03994V25.1566H0V27.0166H29V25.1566H15.458Z"
                  fill="white"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_1_5324">
                  <rect width="29" height="29" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="p_text">No Organizations</div>
        </div>
      ) : (
        <div className="organization_list">
          {FilterList.map((el, i) => (
            <div key={i} className={"box"}>
              <ItemCard
                {...{
                  name: el.name,
                  desc: el.description,
                  type: el.type,
                  image: el.picture,
                  active: el.active,
                  onclick: () => {
                    setViewData(el);
                    setMaintainObj(el);
                    setPages("orgview");
                  },
                }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
