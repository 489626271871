import { combineReducers, createStore } from "redux";
import AppData from "./appdata";

const rootReducer = combineReducers({
  checktoken_data: AppData,
});

const store = createStore(rootReducer);

export default store;
