import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const MessageBox = ({ type, msg, setmodal, ChatData }) => {
  const nav = useNavigate();
  const current_store = useSelector((state) => state.checktoken_data);
  switch (type) {
    case "text":
      return (
        <div className="chat_msg">
          {msg?.includes("\n")
            ? msg?.split("\n").map((line, i) => (
                <>
                  {line}
                  <br />
                </>
              ))
            : msg}
        </div>
      );
      break;
    case "image":
      let data;
      try {
        data = JSON?.parse(msg);
      } catch (error) {
        return <div>{msg}</div>;
      }
      return (
        <div className="image_msg">
          <div
            className={"image_box"}
            onClick={() => {
              setmodal({ type: type, list: data });
            }}
          >
            {data?.length > 1 ? (
              <div className="image_box_len">+{data?.slice(1)?.length}</div>
            ) : (
              ""
            )}
            <img src={data[0]} />
          </div>
        </div>
      );
      break;
    case "video":
      let data3;
      try {
        data3 = JSON.parse(msg);
      } catch (error) {
        return <div>{msg}</div>;
      }
      return (
        <div className="video_msg">
          <div
            className={"video_box "}
            onClick={() => {
              setmodal({ type: type, list: data3 });
            }}
          >
            {data3?.length > 1 ? (
              <div className="video_box_len">+{data3?.slice(1)?.length}</div>
            ) : (
              ""
            )}
            <div className="video_ply_btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xlink="http://www.w3.org/1999/xlink"
                width="24"
                height="24"
                x="0"
                y="0"
                viewBox="0 0 512 512"
                style={{ enableBackground: "new 0 0 512 512" }}
                space="preserve"
                class=""
              >
                <g>
                  <circle
                    cx="256.5"
                    cy="256"
                    r="256"
                    fill="#2497f3"
                    transform="rotate(-45 256.472 256.066)"
                    opacity="1"
                    data-original="#2497f3"
                    class=""
                  ></circle>
                  <path
                    fill="#ffffff"
                    fillRule="evenodd"
                    d="m383.55 233.228-172.85-99.79a26.3 26.3 0 0 0-39.44 22.762v199.6a26.039 26.039 0 0 0 13.14 22.77 26.067 26.067 0 0 0 26.3 0l172.85-99.8a26.3 26.3 0 0 0 0-45.54z"
                    opacity="1"
                    data-original="#ffffff"
                  ></path>
                </g>
              </svg>
            </div>
            <video src={data3[0]} />
          </div>
        </div>
      );
      break;
    case "audio":
      let data4;
      try {
        data4 = JSON.parse(msg);
      } catch (error) {
        return <div>{msg}</div>;
      }
      return (
        <div className="audio_msg">
          <div className={"audio_box "}>
            {data4.map((el, i) => (
              <audio controls key={i}>
                <source src={el} type="audio/mpeg" />
              </audio>
            ))}
          </div>
        </div>
      );
      break;
    case "profile":
      let profile;
      try {
        profile = JSON.parse(msg);
      } catch (error) {

        return <div>{msg}</div>;
      }
      return (
        <div
          className="profile_section"
          style={{
            cursor:
              current_store.userdetail.id != profile.id ? "pointer" : "unset",
          }}
          onClick={() => {
            if (current_store.userdetail.id != profile.id) {
              localStorage.setItem("open_indi_profile", profile.id);
              const goto = btoa(
                `goback=/message?${ChatData._id}&userid=${profile.id}`
              );
              nav(`/individuals?${goto}`);
            }
          }}
        >
          <div className="profile_section_image">
            <img src={profile?.picture} />
          </div>
          <div>
            <div className="profile_name">{profile?.name}</div>
            <div className="profile_serseeking">
              {profile?.servicesSeeking?.map((el, i) => (
                <div key={i}>{el}</div>
              ))}
            </div>
          </div>
        </div>
      );
    default:
      break;
  }
};
