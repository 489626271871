import React, { useEffect, useState } from "react";
import { CheckBox } from "../CheckBox/CheckBox";

export const FilterNav = ({
  setFilterObj,
  FilterObj,
  CurrentFilter,
  setCurrentFilter,
  list,
}) => {
  const [CurrentObj, setCurrentObj] = useState("");
  useEffect(() => {
    setCurrentObj(list?.data[0]?.title);
  }, []);
  return (
    <>
      <div className="filter_container">
        <div>
          {CurrentObj != "empty" ? (
            <div className="filter_left">
              {list?.data?.map((el, i) => (
                <div
                  key={i}
                  className={" " + (CurrentObj == el?.title ? "active_f1" : "")}
                  onClick={() => {
                    setCurrentObj(el.title);
                  }}
                >
                  <div>{el?.title}</div>

                  {CurrentFilter[el?.title]?.length ? (
                    <div className="fil_left_num">
                      {CurrentFilter[el?.title]?.length}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
          {list?.data?.length > 0 ? (
            <div className="filter_right">
              {list.data
                .filter((el1) => el1.title === CurrentObj)[0]
                ?.options?.map((el, i) => (
                  <div
                    key={i}
                    onClick={() => {
                      setCurrentFilter((prev) => {
                        let data =
                          Object.keys(CurrentFilter).length == 0
                            ? {
                                [CurrentObj]: [el],
                              }
                            : Object.keys(prev).includes(CurrentObj)
                            ? {
                                ...prev,
                                [CurrentObj]: prev[CurrentObj]?.includes(el)
                                  ? [
                                      ...prev[CurrentObj].filter(
                                        (el1) => el1 != el
                                      ),
                                    ]
                                  : list.data.filter(
                                      (el1) => el1.title === CurrentObj
                                    )[0].multiselect
                                  ? [...prev[CurrentObj], el]
                                  : [el],
                              }
                            : {
                                ...prev,
                                [CurrentObj]: [el],
                              };
                        return data;
                      });
                    }}
                  >
                    <CheckBox
                      {...{
                        check: CurrentFilter[CurrentObj]?.includes(el),
                        onclick: () => {},
                      }}
                    />
                    {el}
                  </div>
                ))}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
