import React, { useEffect, useState } from "react";

let unique_id = 0;
export const get_unique = () => {
  return (unique_id += 1);
};

export const Inputs = ({ inpType, attr, data }) => {
  const [curr_id, setCurr_id] = useState("");

  useEffect(() => {
    setCurr_id("inpid_" + get_unique());
  }, []);

  const current_inp_obj = {
    readonly: false,
  };
  const autoExpand = (event) => {
    const textarea1 = document.getElementById(attr?.id);
    if (textarea1) {
      if (data?.special_textarea) {
        textarea1.style.height = 30 + "px";
        if (textarea1.scrollHeight < 30) {
          textarea1.style.height = 30 + "px";
        } else {
          // textarea1.style.height = 150 + "px";
          textarea1.style.height = textarea1.scrollHeight + "px";
        }
      } else {
        textarea1.style.height = "auto";
        if (textarea1.scrollHeight < 112) {
          if (data?.type == "textarea") {
            textarea1.style.height = 112 + "px";
          }

          textarea1.style.height = textarea1.scrollHeight + "px";
        } else {
          // textarea1.style.height = 150 + "px";
          textarea1.style.height = textarea1.scrollHeight + "px";
        }
      }
    }
  };
  useEffect(() => {
    autoExpand();
  }, [attr.id]);
  switch (inpType) {
    case "type1":
      return (
        <>
          <label
            className={
              "cust_input with_prefix" +
              (attr?.class ? ` ${attr.class}` : "") +
              (attr?.error ? " err_active" : "")
            }
            htmlFor={curr_id}
            onClick={(e) => {
              if (attr.readonly || current_inp_obj.readonly) {
                e.stopPropagation();
              }
            }}
          >
            <div
              className={
                "input_group_main type1 " + (attr.readonly ? "read_only" : "")
              }
            >
              {data?.prefix ? (
                <div className="input_prefix">{data.prefix}</div>
              ) : (
                ""
              )}
              <div className={"input_group2 "}>
                {data?.type == "textarea" ? (
                  <textarea
                    type={attr?.type || "text"}
                    rows="2"
                    cols="5"
                    inputMode={attr?.inputMode || "email"}
                    value={
                      attr?.value
                        ? attr?.value
                        : Number(attr?.value) === 0
                        ? attr?.value
                        : ""
                    }
                    onInput={autoExpand}
                    autoComplete="off"
                    onChange={attr.event.change}
                    onKeyDown={attr.event.onkeydown}
                    id={attr?.id ? attr.id : curr_id}
                    placeholder={data?.placeholder}
                    className={data.title ? " active_pad" : " unactive_pad"}
                    readOnly={attr.readonly || current_inp_obj.readonly}
                  />
                ) : (
                  <input
                    type={attr?.type || "text"}
                    value={
                      attr?.value
                        ? attr?.value
                        : Number(attr?.value) === 0
                        ? attr?.value
                        : ""
                    }
                    autoComplete="off"
                    inputMode={attr?.inputMode || "email"}
                    onChange={attr.event.change}
                    onKeyDown={attr.event.onkeydown}
                    id={attr?.id ? attr.id : curr_id}
                    placeholder={data?.placeholder}
                    className={data.title ? " active_pad" : " unactive_pad"}
                    readOnly={attr.readonly || current_inp_obj.readonly}
                  />
                )}
                {data?.title ? (
                  <div
                    className="cust_input_title"
                    active={attr.value ? "true" : ""}
                  >
                    <div>{data.title}</div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {data?.suffix ? (
                <div
                  className="input_suffix"
                  style={
                    attr.readonly || current_inp_obj.readonly
                      ? {
                          color: "#777777",
                        }
                      : {}
                  }
                >
                  {data.suffix}
                </div>
              ) : (
                ""
              )}
            </div>

            {attr?.error_msg ? (
              <div className="input_error">
                <div>{attr.error_msg}</div>
              </div>
            ) : (
              ""
            )}
          </label>
        </>
      );
      break;
    case "type2":
      return (
        <>
          <label
            className={
              "cust_input with_prefix" +
              (attr?.class ? ` ${attr.class}` : "") +
              (attr?.error ? " err_active_type2" : "")
            }
            htmlFor={curr_id}
            onClick={(e) => {
              if (attr.readonly || current_inp_obj.readonly) {
                e.stopPropagation();
              }
            }}
          >
            <div
              className={
                "input_group_main type2 " + (attr.readonly ? "read_only" : "")
              }
            >
              {data?.prefix ? (
                <div className="input_prefix">{data.prefix}</div>
              ) : (
                ""
              )}
              <div className={"input_group2 "}>
                {data?.type == "textarea" ? (
                  <textarea
                    type={attr?.type || "text"}
                    rows="2"
                    cols="5"
                    inputMode={attr?.inputMode || "email"}
                    value={
                      attr?.value
                        ? attr?.value
                        : Number(attr?.value) === 0
                        ? attr?.value
                        : ""
                    }
                    onInput={autoExpand}
                    autoComplete="off"
                    onChange={attr.event.change}
                    onKeyDown={attr.event.onkeydown}
                    id={attr?.id ? attr.id : curr_id}
                    placeholder={data?.placeholder}
                    className={data.title ? " active_pad" : " unactive_pad"}
                    readOnly={attr.readonly || current_inp_obj.readonly}
                  />
                ) : (
                  <input
                    type={attr?.type || "text"}
                    value={
                      attr?.value
                        ? attr?.value
                        : Number(attr?.value) === 0
                        ? attr?.value
                        : ""
                    }
                    autoComplete="off"
                    inputMode={attr?.inputMode || "email"}
                    onChange={attr.event.change}
                    onKeyDown={attr.event.onkeydown}
                    id={attr?.id ? attr.id : curr_id}
                    placeholder={data?.placeholder}
                    className={data.title ? " active_pad" : " unactive_pad"}
                    readOnly={attr.readonly || current_inp_obj.readonly}
                  />
                )}
                {data?.title ? (
                  <div
                    className="cust_input_title"
                    active={attr.value ? "true" : ""}
                  >
                    <div>{data.title}</div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {data?.suffix ? (
                <div
                  className="input_suffix"
                  style={
                    attr.readonly || current_inp_obj.readonly
                      ? {
                          color: "#777777",
                        }
                      : {}
                  }
                >
                  {data.suffix}
                </div>
              ) : (
                ""
              )}
            </div>

            {attr?.error_msg ? (
              <div className="input_error">
                <div>{attr.error_msg}</div>
              </div>
            ) : (
              ""
            )}
          </label>
        </>
      );
      break;

    default:
      break;
  }
};
