import React, { useEffect, useState } from "react";
import { BottomNav } from "../../../component/Navbar/BottomNav";
import { CustomNavbar } from "../../../component/Navbar/CustomNavbar";
import { ItemCard } from "../../../component/Card/ItemCard";
import { ViewPendingCard } from "../../../component/Card/ViewPendingCard";
import { Inputs } from "../../../component/Input/Inputs";
import { CurrentSection } from "../../../component/AnalyticsComp/CurrentSection";
import { date_filter, dateFilters, scrollToTop } from "../../../utils/utils";
import { isAfter, parseISO } from "date-fns";
import {
  admintoken,
  CheckToken,
  OrganizationList,
} from "../../../component/Api/api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

export const PendingRequests = ({ setloading }) => {
  const current_store = useSelector((state) => state.checktoken_data);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [List, setList] = useState([]);
  const [FilterList, setFilterList] = useState([]);
  const [ViewData, setViewData] = useState({});
  const [Search, setSearch] = useState("");
  const [FilterObj, setFilterObj] = useState({});
  const [CurrentFilter, setCurrentFilter] = useState({});
  const [Calfa, setCalfa] = useState("");
  const [Doso, setDoso] = useState("");
  const [FilterValue, setFilterValue] = useState("");
  useEffect(() => {
    scrollToTop();
    setloading(true);
    if (admintoken || current_store?.token) {
      CheckToken(admintoken || current_store?.token, ({ status, response }) => {
        if (response.status) {
          if (response?.user?.type) {
            if (response?.user?.type == "admin") {
              dispatch({
                type: "setCheckToken",
                payload: {
                  token: admintoken || current_store?.token,
                  userdetail: response.user,
                  user_type: response?.user?.type,
                },
              });
            } else {
              nav("/organization");
            }
          } else {
            nav("/welcome");
          }
        } else {
          setloading(false);
          nav("/admin");
        }
      });
    } else {
      setloading(false);
      nav("/admin");
    }
    org();
  }, []);
  const org = () => {
    OrganizationList(
      admintoken || current_store?.token,
      ({ status, response }) => {
        if (response.status) {
          setloading(false);
          setList(response.organizations);
        }
      }
    );
  };
  useEffect(() => {
    FunctionFilter();
  }, [List]);

  const FunctionFilter = (value, filvalue) => {
    const data = List?.sort(
      (b, a) => new Date(a.createdAt) - new Date(b.createdAt)
    )
      .filter(
        (el) => (el?.directlyImpactedOrg || el?.governmentOrg) && !el.dioApproval
      )
      ?.filter((el) =>
        Object.values(el).join(",").toLowerCase().match(value?.toLowerCase())
      )
      .filter((item) =>
        filvalue
          ? dayjs(item.createdAt).isBetween(
              dateFilters[filvalue?.toLowerCase()](),
              filvalue === "today" ? dayjs().endOf("day") : dayjs(),
              null,
              "[]"
            )
          : true
      );
    setFilterList(data);
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const boxElements = document.getElementsByClassName("pending_req");
      for (let i = 0; i < boxElements.length; i++) {
        const { top, bottom } = boxElements[i].getBoundingClientRect();
        if (top >= 130 && bottom <= window.innerHeight) {
          setCalfa(FilterList[i].name);
          break;
        }
      }
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [FilterList, Search]);
  useEffect(() => {
    if (FilterObj["Requests"]?.length) {
      FunctionFilter(Search, FilterObj["Requests"][0]);
    } else {
      FunctionFilter(Search, "");
    }
  }, [FilterObj, Search]);

  useEffect(() => {
    if (Doso == "accept") {
      setInterval(() => {
        setDoso("");
        setViewData({});
        org();
      }, 1500);
    }
  }, [Doso]);

  return (
    <>
      <CustomNavbar
        {...{
          filter_option: true,
          type: "admin",
          menu_ctr: Object.keys(ViewData).length ? false : true,
          setFilterObj,
          FilterObj,
          CurrentFilter,
          setCurrentFilter,
          list: {
            data: [
              {
                title: "Requests",
                multiselect: false,
                options: ["Today", "Last 7 days", "Last 14 days", "Last month"],
              },
            ],
          },
          btn1: {
            txt: Object.keys(ViewData).length ? (
              <svg
                width="25"
                height="26"
                viewBox="0 0 25 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.8973 13.8618H4.59813L8.02727 17.287C9.01152 18.2713 7.53514 19.7516 6.55089 18.7673L3.42884 15.6413L1.34223 13.5547C1.14749 13.3592 1.03816 13.0945 1.03816 12.8185C1.03816 12.5425 1.14749 12.2778 1.34223 12.0823L6.55483 6.86969C6.65307 6.76892 6.77068 6.68907 6.90059 6.63494C7.03049 6.58081 7.17001 6.55353 7.31073 6.55473C7.51858 6.55527 7.72153 6.61788 7.89355 6.73454C8.06557 6.85119 8.19883 7.01656 8.27625 7.20946C8.35366 7.40235 8.37169 7.61397 8.32803 7.81718C8.28437 8.02039 8.18101 8.20592 8.03121 8.35L4.5942 11.7752H22.9525C24.3973 11.85 24.291 13.9327 22.8973 13.8618Z"
                  fill="#D0D0D1"
                />
              </svg>
            ) : (
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 7.99935C4 7.64573 4.14048 7.30659 4.39052 7.05654C4.64057 6.80649 4.97971 6.66602 5.33333 6.66602H26.6667C27.0203 6.66602 27.3594 6.80649 27.6095 7.05654C27.8595 7.30659 28 7.64573 28 7.99935C28 8.35297 27.8595 8.69211 27.6095 8.94216C27.3594 9.19221 27.0203 9.33268 26.6667 9.33268H5.33333C4.97971 9.33268 4.64057 9.19221 4.39052 8.94216C4.14048 8.69211 4 8.35297 4 7.99935ZM4 23.9993C4 23.6457 4.14048 23.3066 4.39052 23.0565C4.64057 22.8065 4.97971 22.666 5.33333 22.666H12C12.3536 22.666 12.6928 22.8065 12.9428 23.0565C13.1929 23.3066 13.3333 23.6457 13.3333 23.9993C13.3333 24.353 13.1929 24.6921 12.9428 24.9422C12.6928 25.1922 12.3536 25.3327 12 25.3327H5.33333C4.97971 25.3327 4.64057 25.1922 4.39052 24.9422C4.14048 24.6921 4 24.353 4 23.9993Z"
                  fill="#D0D0D1"
                />
                <path
                  d="M4.39052 15.3905C4.14048 15.6406 4 15.9797 4 16.3333C4 16.687 4.14048 17.0261 4.39052 17.2761C4.64057 17.5262 4.97971 17.6667 5.33333 17.6667H18.6667C19.0203 17.6667 19.3594 17.5262 19.6095 17.2761C19.8595 17.0261 20 16.687 20 16.3333C20 15.9797 19.8595 15.6406 19.6095 15.3905C19.3594 15.1405 19.0203 15 18.6667 15H5.33333C4.97971 15 4.64057 15.1405 4.39052 15.3905Z"
                  fill="#D0D0D1"
                />
              </svg>
            ),
            onclick: () => {
              if (Object.keys(ViewData).length) {
                setViewData({});
              } else {
              }
            },
          },
          txt: Object.keys(ViewData).length
            ? "Impacted Organization"
            : "Pending Requests",
          btn2: {
            txt: Object.keys(ViewData).length ? (
              ""
            ) : (
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1_5372)">
                  <path
                    d="M0.78125 4.40757H12.676C13.0345 6.03936 14.4917 7.2644 16.2296 7.2644C17.9675 7.2644 19.4248 6.0394 19.7833 4.40757H24.2188C24.6502 4.40757 25 4.05776 25 3.62632C25 3.19487 24.6502 2.84507 24.2188 2.84507H19.7829C19.4237 1.21411 17.9645 -0.0117188 16.2296 -0.0117188C14.4939 -0.0117188 13.0354 1.21392 12.6763 2.84507H0.78125C0.349805 2.84507 0 3.19487 0 3.62632C0 4.05776 0.349805 4.40757 0.78125 4.40757ZM14.1542 3.62837L14.1542 3.61997C14.1576 2.479 15.0886 1.55083 16.2296 1.55083C17.369 1.55083 18.3001 2.47773 18.305 3.61816L18.3052 3.62964C18.3034 4.77256 17.373 5.70195 16.2296 5.70195C15.0868 5.70195 14.1567 4.77349 14.1541 3.63125L14.1542 3.62837ZM24.2188 20.5932H19.7829C19.4236 18.9623 17.9645 17.7364 16.2296 17.7364C14.4939 17.7364 13.0354 18.9621 12.6763 20.5932H0.78125C0.349805 20.5932 0 20.9429 0 21.3744C0 21.8059 0.349805 22.1557 0.78125 22.1557H12.676C13.0345 23.7874 14.4917 25.0125 16.2296 25.0125C17.9675 25.0125 19.4248 23.7874 19.7833 22.1557H24.2188C24.6502 22.1557 25 21.8059 25 21.3744C25 20.9429 24.6502 20.5932 24.2188 20.5932ZM16.2296 23.45C15.0868 23.45 14.1567 22.5215 14.1541 21.3793L14.1542 21.3765L14.1542 21.3681C14.1576 20.2271 15.0886 19.2989 16.2296 19.2989C17.369 19.2989 18.3001 20.2258 18.305 21.3662L18.3052 21.3776C18.3035 22.5207 17.3731 23.45 16.2296 23.45ZM24.2188 11.7191H12.324C11.9655 10.0874 10.5083 8.86235 8.77036 8.86235C7.03247 8.86235 5.57524 10.0874 5.21675 11.7191H0.78125C0.349805 11.7191 0 12.0689 0 12.5004C0 12.9319 0.349805 13.2816 0.78125 13.2816H5.21714C5.57637 14.9125 7.03555 16.1384 8.77036 16.1384C10.5061 16.1384 11.9646 14.9127 12.3237 13.2816H24.2188C24.6502 13.2816 25 12.9319 25 12.5004C25 12.0689 24.6502 11.7191 24.2188 11.7191ZM10.8458 12.4983L10.8458 12.5067C10.8424 13.6477 9.91138 14.5759 8.77036 14.5759C7.63096 14.5759 6.6999 13.649 6.69497 12.5086L6.69482 12.4972C6.69653 11.3541 7.62695 10.4249 8.77036 10.4249C9.91323 10.4249 10.8433 11.3533 10.8459 12.4956L10.8458 12.4983Z"
                    fill="#D0D0D1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1_5372">
                    <rect width="25" height="25" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            ),
            onclick: () => {
              if (Object.keys(ViewData).length) {
                setViewData({});
              } else {
              }
            },
          },
        }}
      />
      {Object.keys(ViewData).length ? (
        <ViewPendingCard {...{ ViewData, setDoso, setloading }} />
      ) : (
        <div className="pending_requests_contaier">
          <div className="pending_requests_top">
            <Inputs
              {...{
                inpType: "type1",
                attr: {
                  class: "search",
                  readonly: "",
                  value: Search,
                  id: "search",
                  type: "text",
                  event: {
                    change: ({ target }) => {
                      if (
                        /^[a-zA-Z0-9\s#@]+$/.test(target.value) ||
                        target.value == ""
                      ) {
                        const data = FilterObj["Requests"]
                          ? FilterObj["Requests"][0]
                          : "";
                        FunctionFilter(Search, data);
                        setSearch(target.value);
                      }
                    },
                  },
                },
                data: {
                  title: "Search",
                  prefix: (
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1_6693)">
                        <path
                          d="M17.8292 17.0207L13.1819 12.4477C14.3989 11.1255 15.1466 9.37682 15.1466 7.45261C15.146 3.33639 11.7556 0 7.57302 0C3.39042 0 0 3.33639 0 7.45261C0 11.5688 3.39042 14.9052 7.57302 14.9052C9.3802 14.9052 11.0377 14.2801 12.3397 13.241L17.005 17.832C17.2323 18.056 17.6014 18.056 17.8287 17.832C17.8827 17.7792 17.9257 17.7162 17.9551 17.6465C17.9844 17.5769 17.9996 17.5021 17.9996 17.4265C17.9997 17.3509 17.9846 17.2761 17.9554 17.2064C17.9261 17.1367 17.8832 17.0736 17.8292 17.0207ZM7.57302 13.7586C4.03408 13.7586 1.16522 10.9353 1.16522 7.45261C1.16522 3.96991 4.03408 1.14663 7.57302 1.14663C11.112 1.14663 13.9808 3.96991 13.9808 7.45261C13.9808 10.9353 11.112 13.7586 7.57302 13.7586Z"
                          fill="#D0D0D1"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1_6693">
                          <rect width="18" height="18" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  ),
                  type: "",
                },
              }}
            />
            <CurrentSection
              {...{
                alfa:
                  Calfa?.charAt(0)?.toUpperCase() ||
                  FilterList[0]?.name?.charAt(0)?.toUpperCase(),
                List: FilterList,
                type: "Pending Request",
              }}
            />
          </div>

          {FilterList.length == 0 ? (
            <div className="pending_requests_nodata">
              <div>
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1_5351)">
                    <path
                      d="M24.1744 20.075C23.707 19.7273 23.0463 19.8244 22.6986 20.2917C22.5065 20.5498 22.3036 20.7999 22.0904 21.041C21.7047 21.4774 21.7457 22.144 22.182 22.5298C22.3745 22.7004 22.6229 22.7946 22.8802 22.7943C23.1719 22.7943 23.4624 22.6739 23.6708 22.4381C23.9232 22.1525 24.1635 21.8564 24.391 21.5506C24.7387 21.0834 24.6417 20.4226 24.1744 20.075ZM25.9382 15.1288C25.3694 15.0049 24.8072 15.3658 24.6833 15.935C24.6149 16.2491 24.5332 16.5602 24.4383 16.8674C24.2664 17.4239 24.5781 18.0144 25.1346 18.1864C25.2384 18.2184 25.3432 18.2336 25.4464 18.2336C25.8967 18.2336 26.3137 17.9429 26.4537 17.49C26.5662 17.1255 26.6633 16.7564 26.7445 16.3836C26.8683 15.8145 26.5074 15.2527 25.9382 15.1288ZM19.0978 23.4528C18.8168 23.6096 18.5293 23.7544 18.2361 23.8869C17.7052 24.1267 17.4692 24.7514 17.709 25.2822C17.8851 25.6723 18.2689 25.9031 18.6708 25.9031C18.8159 25.9031 18.9634 25.873 19.1043 25.8094C19.452 25.6522 19.7929 25.4804 20.1261 25.2945C20.6347 25.0105 20.8168 24.368 20.5328 23.8595C20.2489 23.3509 19.6064 23.1688 19.0978 23.4528ZM12.4459 5.0625V13.0631L8.57842 16.9306C8.16657 17.3425 8.16657 18.0103 8.57842 18.4221C8.67625 18.5202 8.79251 18.598 8.9205 18.651C9.04849 18.704 9.18571 18.7312 9.32425 18.731C9.46278 18.7312 9.59999 18.704 9.72798 18.651C9.85597 18.598 9.97223 18.5202 10.0701 18.4221L14.2464 14.2458C14.4442 14.048 14.5553 13.7797 14.5553 13.5V5.0625C14.5553 4.48 14.0831 4.00781 13.5006 4.00781C12.9181 4.00781 12.4459 4.48 12.4459 5.0625Z"
                      fill="white"
                    ></path>
                    <path
                      d="M25.9453 2.26758C25.3628 2.26758 24.8906 2.73976 24.8906 3.32227V6.25092C22.4357 2.40089 18.1486 0 13.5 0C9.89402 0 6.50389 1.40426 3.95402 3.95402C1.40426 6.50389 0 9.89402 0 13.5C0 17.106 1.40426 20.4961 3.95402 23.046C6.50389 25.5957 9.89402 27 13.5 27C13.5089 27 13.5175 26.9989 13.5264 26.9987C13.5352 26.9989 13.5438 27 13.5527 27C13.9328 27 14.3167 26.984 14.6937 26.9524C15.2742 26.9038 15.7054 26.3939 15.6568 25.8134C15.6081 25.233 15.0989 24.8016 14.5177 24.8504C14.199 24.8771 13.8744 24.8906 13.5527 24.8906C13.5438 24.8906 13.5352 24.8917 13.5264 24.8919C13.5175 24.8917 13.5089 24.8906 13.5 24.8906C7.21918 24.8906 2.10938 19.7808 2.10938 13.5C2.10938 7.21918 7.21918 2.10938 13.5 2.10938C17.5478 2.10938 21.2708 4.2669 23.3052 7.69922H20.4052C19.8227 7.69922 19.3505 8.1714 19.3505 8.75391C19.3505 9.33641 19.8227 9.80859 20.4052 9.80859H23.625C24.2443 9.80931 24.8516 9.6386 25.3798 9.31537C25.4125 9.29682 25.4441 9.27654 25.4746 9.25462C26.3924 8.65076 27 7.61205 27 6.43359V3.32227C27 2.73976 26.5278 2.26758 25.9453 2.26758Z"
                      fill="white"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_1_5351">
                      <rect width="27" height="27" fill="white"></rect>
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="p_text">No Pending Requests</div>
            </div>
          ) : (
            <div className="pending_requests_list">
              {FilterList.map((el, i) => (
                <div key={i} className="pending_req">
                  <ItemCard
                    {...{
                      name: el.name,
                      desc: el.description,
                      type: el.type,
                      image: el.picture,
                      onclick: () => {
                        setViewData(el);
                      },
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      {Doso == "accept" ? (
        <div className="pending_requests_done">
          <svg
            width="82"
            height="82"
            viewBox="0 0 82 82"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1_7407)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M41 0C18.3859 0 0 18.3859 0 41C0 63.6141 18.3859 82 41 82C63.6141 82 82 63.6141 82 41C82 18.3859 63.6141 0 41 0Z"
                fill="#25B871"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M60.8271 27.1786C61.8201 28.1716 61.8201 29.8052 60.8271 30.7981L36.8037 54.8216C36.3072 55.3181 35.6506 55.5743 34.9939 55.5743C34.3373 55.5743 33.6807 55.3181 33.1842 54.8216L21.1725 42.8099C20.1795 41.8169 20.1795 40.1833 21.1725 39.1903C22.1654 38.1974 23.799 38.1974 24.792 39.1903L34.9939 49.3923L57.2076 27.1786C58.2006 26.1696 59.8342 26.1696 60.8271 27.1786Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_7407">
                <rect width="82" height="82" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <div>You have accepted the request for</div>
          <div className="pending_requests_do_name">{ViewData?.name}</div>
          <div className="pending_requests_do_notify">
            We will notify them soon
          </div>
        </div>
      ) : (
        ""
      )}

      <BottomNav type={"admin"} />
    </>
  );
};
