import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const NoPage = ({ setloading }) => {
  const nav = useNavigate(0);
  useEffect(() => {
    setloading(true);
    nav("/login");
  }, []);

  return <div>No Page</div>;
};
