import React, { useEffect } from "react";
import { ViewCard } from "../../../component/Card/ViewCard";
import { scrollToTop } from "../../../utils/utils";
export const OrganizationView = ({
  FilterList,
  Calfa,
  FilterObj,
  setPages,
  FunctionFilter,
  ViewData,
  setViewData,
  Edit,
  setEdit,
  Search,
  setSearch,
}) => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <ViewCard
        {...{
          ViewData,
        }}
      />
    </>
  );
};
