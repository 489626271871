import React, { useEffect, useState } from "react";
import { date_formatter } from "../../utils/utils";
import { CheckBox } from "../CheckBox/CheckBox";

export const ViewCard = ({ ViewData }) => {
  const [CheckBoxList, setCheckBoxList] = useState({
    active: false,
    inactive: false,
    delete: false,
  });
  return (
    <div className="view_container">
      <div className="organization_form_profile_photo">
        <div
          className="user_profile_img"
          style={{
            backgroundImage: `url(${
              ViewData?.picture || "https://imgur.com/Yjchy3r.png"
            })`,
          }}
        ></div>
      </div>
      <div className="view_detail_title">{ViewData.name}</div>
      <div className="view_detail_type">{ViewData.type}</div>
      <div className="view_detail">
        <div className="view_detail_t">Organization Name</div>
        <div className="view_detail_a">{ViewData?.name}</div>
      </div>

      <div className="view_detail">
        <div className="view_detail_t">Phone</div>
        <div className="view_detail_a">{ViewData?.phone}</div>
      </div>
      <div className="view_detail">
        <div className="view_detail_t">Email</div>
        <div className="view_detail_a">{ViewData?.email}</div>
      </div>
      <div className="view_detail">
        <div className="view_detail_t">Address</div>
        <div className="view_detail_a">{ViewData?.address}</div>
      </div>
      <div className="view_detail">
        <div className="view_detail_t">Status</div>
        <div className="view_detail_a">
          {ViewData?.active ? "Active" : "In-Active"}
        </div>
      </div>
      <div className="view_detail">
        <div className="view_detail_t">Website</div>
        <div className="view_detail_a">{ViewData?.website}</div>
      </div>

      <div className="view_detail">
        <div className="view_detail_t">Contact Person Name</div>
        <div className="view_detail_a">{ViewData?.contactPersonName}</div>
      </div>
      <div className="view_detail">
        <div className="view_detail_t">
          When did the organization join ACC=SS?
        </div>
        <div className="view_detail_a">
          {date_formatter(new Date(ViewData.createdAt), "date/month/year_long")}
        </div>
      </div>

      <div className="view_detail">
        <div className="view_detail_t">
          Is this a directly impacted organization?
        </div>
        <div className="view_detail_a">
          {ViewData.directlyImpactedOrg ? "Yes" : "No"}
        </div>
      </div>
      <div className="view_detail">
        <div className="view_detail_t">Is this a government entity?</div>
        <div className="view_detail_a">
          {ViewData?.governmentOrg ? "Yes" : "No"}
        </div>
      </div>
      <div className="view_detail">
        <div className="view_detail_t">
          {" "}
          Do you exclude individuals with a history of violence?
        </div>
        <div className="view_detail_a">
          {ViewData.excludeViolenceHistory ? "Yes" : "No"}
        </div>
      </div>
      <div className="view_detail">
        <div className="view_detail_t">
          {" "}
          Do you exclude people with sexual offenses?
        </div>
        <div className="view_detail_a">
          {ViewData.excludeSexualOffenses ? "Yes" : "No"}
        </div>
      </div>

      <div className="view_detail">
        <div className="view_detail_t">Services offered</div>
        <div className="view_detail_a">
          {ViewData.servicesOffered.map((el, i) => (
            <div className="chip_box" key={i}>
              {el}
            </div>
          ))}
        </div>
      </div>
      <div className="view_detail">
        <div className="view_detail_t">Description</div>
        <div className="view_detail_a">{ViewData?.description}</div>
      </div>
    </div>
  );
};
