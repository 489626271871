import React from "react";
import { IndiList } from "./IndiList";
import { IndiView } from "./IndiView";
import { IndiEdit } from "./IndiEdit";

export const IndiAll = ({ pages, setPages, otherdata }) => {
  const data = {
    list: (
      <IndiList
        {...{
          setPages,
          ...otherdata,
        }}
      />
    ),
    indiview: (
      <IndiView
        {...{
          setPages,
          ...otherdata,
        }}
      />
    ),
    indiedit: (
      <IndiEdit
        {...{
          setPages,
          ...otherdata,
        }}
      />
    ),
  };
  return data[pages];
};
