import React from "react";
import { CustRange } from "../Ranges/CustRange";

export const RangeBox = ({ title, type, lastday, list, total }) => {
  return (
    <div className="group_ranges_box">
      <div className="group_ranges_box_title">
        {title} <span>{type}</span>
      </div>
      <div className="group_ranges_box_desc">{lastday}</div>
      {list?.length == 0 ? (
        <div className="group_ranges_box_nodata">No Data</div>
      ) : (
        list?.map((el, i) => (
          <div className="ranges_box_list" key={i}>
            <div className="ranges_name">{el?._id}</div>
            <CustRange
              {...{
                range: el?.count,
                total: total,
              }}
            />
          </div>
        ))
      )}
    </div>
  );
};
