import React, { useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

export const Graph = ({ graph_width, graph_data = [] }) => {
  return (
    <div
      className="graph"
    
    >
      <LineChart width={graph_width || 350} height={200} data={graph_data}>
        <CartesianGrid
          strokeDasharray="10 10"
          horizontal={false}
          vertical={true}
        />
        <XAxis
          dataKey="date"
          padding={{ left: 0, right: 0 }}
          stroke="#777777"
        />
        <YAxis
          stroke="#777777"
          strokeDasharray="10 10"
          width={25}
          interval={1}
        />
        {/* <Tooltip content={<CustomTooltip />} /> */}
        {/* <Legend /> */}
        <Line
          type="monotone"
          dataKey="count"
          stroke={"#4c5aba"}
          strokeWidth={2}
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </div>
  );
};
