import React from "react";

export const CreateProfileNav = ({ title, btn1, btn2 }) => {
  return (
    <div className="create_profile_nav">
      <div>
        {btn1?.name ? (
          <div
            className="create_prof_back_btn"
            onClick={() => {
              btn1?.clickback();
            }}
          >
            {btn1?.name}
          </div>
        ) : (
          ""
        )}
        {title ? <div>{title}</div> : ""}
        {btn2?.name ? (
          <div
            className="create_profile_next"
            onClick={() => {
              btn2.clicknext();
            }}
          >
            {btn2?.name}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
