import { useLocation } from "react-router-dom";

const useQueryParams = () => {
  const location = useLocation();

  const data = location?.search ? atob(location?.search?.split("?")[1]) : "";
  const params = new URLSearchParams(data);

  const getQueryParam = (param) => {
    return params.get(param);
  };

  return getQueryParam;
};

export default useQueryParams;
