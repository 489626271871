import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomNavbar } from "../component/Navbar/CustomNavbar";
import { CheckToken, token } from "../component/Api/api";
import { useDispatch, useSelector } from "react-redux";
import { checkPlan } from "../utils/utils";
import { RTE_Readonly } from "../component/Rich text editor/RTE_Readonly";

export const CheckOut = ({ setloading }) => {
  const current_store = useSelector((state) => state.checktoken_data);
  const token = localStorage.getItem("user_token");
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [FilterObj, setFilterObj] = useState({});
  const [CurrentFilter, setCurrentFilter] = useState({});
  useEffect(() => {
    setloading(true);
    if (current_store?.token || token) {
      CheckToken(current_store?.token || token, ({ status, response }) => {
        if (response.status) {
          if (response?.user?.type) {
            if (
              response?.user?.type == "organization" ||
              response?.user?.type == "individual"
            ) {
              dispatch({
                type: "setCheckToken",
                payload: {
                  token: current_store?.token || token,
                  userdetail: response.user,
                  user_type: response?.user?.type,
                },
              });
              checkPlan({
                ...response.user,
                ongoto: () => {
                  nav("/checkout");
                },
                ongopending: () => {
                  nav("/requestprocessing");
                },
              });
              setloading(false);
            } else {
              setloading(false);
              nav("/admin/dashboard");
            }
          } else {
            nav("/welcome");
          }
        } else {
          setloading(false);
          nav("/login");
        }
      });
    } else {
      setloading(false);
      nav("/login");
    }
  }, []);
  const data = [
    {
      type: "heading-1",
      align: "left",
      children: [
        {
          text: "💼 Services and Payments",
        },
      ],
    },
    {
      type: "heading-1",
      align: "left",
      children: [
        {
          text: "",
        },
      ],
    },
    {
      type: "heading-3",
      align: "left",
      children: [
        {
          text: "🌟 Unlimited Access Plan",
        },
      ],
    },
    {
      type: "paragraph",
      align: "left",
      children: [
        {
          text: "- ",
        },
        {
          text: "Price",
          bold: true,
        },
        {
          text: ":💲99.99/month",
        },
      ],
    },
    {
      type: "paragraph",
      align: "left",
      children: [
        {
          text: "- ",
        },
        {
          text: "Features:",
          bold: true,
        },
      ],
    },
    {
      type: "paragraph",
      align: "left",
      children: [
        {
          text: "  - 📋 Access to a complete list of individuals",
        },
      ],
    },
    {
      type: "paragraph",
      align: "left",
      children: [
        {
          text: "  - 💬 Unlimited conversations",
        },
      ],
    },
    {
      type: "paragraph",
      align: "left",
      children: [
        {
          text: "  - 📊 Total analytics",
        },
      ],
    },
    {
      type: "paragraph",
      align: "left",
      children: [
        {
          text: "  - 🥇 Priority support",
        },
      ],
    },
    {
      type: "paragraph",
      align: "left",
      children: [
        {
          text: "  - 📑 Detailed reports",
        },
      ],
    },
    {
      type: "paragraph",
      align: "left",
      children: [
        {
          text: "  - 🔍 Advanced search and filtering options",
        },
      ],
    },
  ];
  return (
    <>
      <CustomNavbar
        {...{
          filter_option: false,
          type: "",
          menu_ctr: false,
          setFilterObj,
          FilterObj,
          CurrentFilter,
          setCurrentFilter,
          btn1: {
            txt: "",
            onclick: () => {},
          },
          txt: "Plans",
          btn2: {
            txt: "",
            onclick: () => {},
          },
        }}
      />
      <div className="main_checkout">
        <div className="inner_checkout">
          <div className="inner_checkout_title">
            <RTE_Readonly read_only={true} msg={data} />
          </div>
          <div
            className="inner_checkout_btn"
            onClick={() => {
              window.location.href = `https://api.acc-ss.com/subscription/${current_store?.userdetail?.id}`;
            }}
          >
            Subscribe
          </div>
        </div>
      </div>
    </>
  );
};
