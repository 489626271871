import React from "react";
import { UIndividualList } from "./UIndividualList";
import { UIndividualView } from "./UIndividualView";
import { UIndividualMaps } from "./UIndividualMaps";
import { UIndividualViewType } from "./UIndividualViewType";
import { UIndividualMessage } from "./UIndividualMessage";
import { UOrgViewsIndi } from "./UOrgViewsIndi";

export const UIndividualAllPages = ({ Page, setPage, otherStates }) => {
  const data = {
    list: (
      <UIndividualList
        {...{
          Page,
          setPage,
          ...otherStates,
        }}
      />
    ),
    indviews: (
      <UIndividualView
        {...{
          Page,
          setPage,
          ...otherStates,
        }}
      />
    ),
    orgviews: (
      <UOrgViewsIndi
        {...{
          Page,
          setPage,
          ...otherStates,
        }}
      />
    ),
    indviewsTypeindi: (
      <UIndividualViewType
        {...{
          Page,
          setPage,
          ...otherStates,
        }}
      />
    ),
    indmaps: (
      <UIndividualMaps
        {...{
          Page,
          setPage,
          ...otherStates,
        }}
      />
    ),
    indmessage: (
      <UIndividualMessage
        {...{
          Page,
          setPage,
          ...otherStates,
        }}
      />
    ),
  };
  return data[Page];
};
