import React from "react";
import { admintoken, ApproveDio, token } from "../Api/api";

export const ViewPendingCard = ({ ViewData, setDoso, setloading }) => {
  return (
    <div className="view_pending_card">
      <div className="view_pending_card_form_profile_photo">
        <div
          className="user_profile_img"
          style={{
            backgroundImage: `url(${
              ViewData?.picture || "https://imgur.com/Yjchy3r.png"
            })`,
          }}
        ></div>
      </div>
      <div className="view_pending_card_title">{ViewData?.name}</div>
      <div className="view_pending_card_type">{ViewData.type}</div>
      <div className="view_pending_card_detail">
        <div className="view_pending_card_detail_title">About us</div>
        <div className="view_pending_card_detail_desc">
          {ViewData.description}
        </div>
      </div>
      <div className="view_pending_card_detail">
        <div className="view_pending_card_detail_title">Email</div>
        <div className="view_pending_card_detail_desc">{ViewData.email}</div>
      </div>
      <div className="view_pending_card_detail">
        <div className="view_pending_card_detail_title">Phone</div>
        <div className="view_pending_card_detail_desc">{ViewData.phone}</div>
      </div>
      <div className="view_pending_card_detail">
        <div className="view_pending_card_detail_title">
          Organization website
        </div>
        <div className="view_pending_card_detail_desc">{ViewData?.website}</div>
      </div>
      <div className="view_pending_card_detail">
        <div className="view_pending_card_detail_title">Area of Interests</div>
        <div className="view_pending_card_detail_list">
          {ViewData?.servicesOffered?.map((el, i) => (
            <div key={i}>{el}</div>
          ))}
        </div>
      </div>
      <div className="view_pending_card_detail_btns">
        <div
          className="reject_card_btn"
          onClick={() => {
            setDoso("reject");
          }}
        >
          Reject
        </div>
        <div
          className="accept_card_btn"
          onClick={() => {
            setloading(true);
            ApproveDio(ViewData.id, admintoken, ({ status, response }) => {
              if (response.status) {
                setloading(false);
                setDoso("accept");
              }
            });
          }}
        >
          Accept
        </div>
      </div>
    </div>
  );
};
