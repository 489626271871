import React from "react";

export const MessageType = ({ type, setType, data, setdata }) => {

  switch (type) {
    case "image":
      return (
        <div className="imageSection">
          <div>
            {data.map((el, i) => (
              <div key={i} className="image_box">
                <div
                  className="image_box_remove"
                  onClick={() => {
                    const t_data = data.filter((item) => item != el);
                    setdata(t_data);
                    if (t_data.length == 0) {
                      setType("text");
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    xlink="http://www.w3.org/1999/xlink"
                    width="16"
                    height="16"
                    x="0"
                    y="0"
                    viewBox="0 0 24 24"
                    style={{ enableBackground: "new 0 0 512 512" }}
                    space="preserve"
                    class=""
                  >
                    <g>
                      <path
                        fill="#fff"
                        fillRule="evenodd"
                        d="M5 17.586a1 1 0 1 0 1.415 1.415L12 13.414 17.586 19A1 1 0 0 0 19 17.586L13.414 12 19 6.414A1 1 0 0 0 17.585 5L12 10.586 6.414 5A1 1 0 0 0 5 6.414L10.586 12z"
                        clipRule="evenodd"
                        opacity="1"
                        data-original="#fff"
                      ></path>
                    </g>
                  </svg>
                </div>
                <img src={el} />
              </div>
            ))}
          </div>
        </div>
      );
      break;
    case "video":
      return (
        <div className="videoSection">
          <div>
            {data.map((el, i) => (
              <div key={i} className="video_box">
                <div
                  className="video_box_remove"
                  onClick={() => {
                    const t_data = data.filter((item) => item != el);
                    setdata(t_data);
                    if (t_data.length == 0) {
                      setType("text");
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    xlink="http://www.w3.org/1999/xlink"
                    width="16"
                    height="16"
                    x="0"
                    y="0"
                    viewBox="0 0 24 24"
                    style={{ enableBackground: "new 0 0 512 512" }}
                    space="preserve"
                    class=""
                  >
                    <g>
                      <path
                        fill="#fff"
                        fillRule="evenodd"
                        d="M5 17.586a1 1 0 1 0 1.415 1.415L12 13.414 17.586 19A1 1 0 0 0 19 17.586L13.414 12 19 6.414A1 1 0 0 0 17.585 5L12 10.586 6.414 5A1 1 0 0 0 5 6.414L10.586 12z"
                        clipRule="evenodd"
                        opacity="1"
                        data-original="#fff"
                      ></path>
                    </g>
                  </svg>
                </div>
                <video src={el} />
              </div>
            ))}
          </div>
        </div>
      );
      break;
    case "audio":
      return (
        <div className="audioSection">
          <div>
            {data.map((el, i) => (
              <div key={i} className="audio_box">
                <div
                  className="audio_box_remove"
                  onClick={() => {
                    const t_data = data.filter((item) => item != el);
                    setdata(t_data);
                    if (t_data.length == 0) {
                      setType("text");
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    xlink="http://www.w3.org/1999/xlink"
                    width="16"
                    height="16"
                    x="0"
                    y="0"
                    viewBox="0 0 24 24"
                    style={{ enableBackground: "new 0 0 512 512" }}
                    space="preserve"
                    class=""
                  >
                    <g>
                      <path
                        fill="#fff"
                        fillRule="evenodd"
                        d="M5 17.586a1 1 0 1 0 1.415 1.415L12 13.414 17.586 19A1 1 0 0 0 19 17.586L13.414 12 19 6.414A1 1 0 0 0 17.585 5L12 10.586 6.414 5A1 1 0 0 0 5 6.414L10.586 12z"
                        clipRule="evenodd"
                        opacity="1"
                        data-original="#fff"
                      ></path>
                    </g>
                  </svg>
                </div>
                <div className="audio_content">
                  {el?.split(".")?.reverse()[0]}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
      break;
    default:
      break;
  }
};
