import React from "react";
import { OrgAnalytics } from "./OrgAnalytics";
import { IndiAnalytics } from "./IndiAnalytics";

export const AnalyticsAll = ({ type, data, FilterObj }) => {
  const List = {
    Organization: (
      <OrgAnalytics
        {...{
          data: data.organization,
          FilterObj,
        }}
      />
    ),
    Individual: (
      <IndiAnalytics
        {...{
          data: data.individual,
          FilterObj,
        }}
      />
    ),
  };
  return List[type];
};
