import React from "react";

export const CurrentSection = ({ alfa, List, type }) => {
  return (
    <div className="currentsection_container">
      <div></div>
      {alfa ? <div className="currentsection_alfa"> {alfa}</div> : ""}
      <div>
        {List.length} {type ? type : "Org"}
      </div>
    </div>
  );
};
