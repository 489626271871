import React from "react";
import { UOrgList } from "./UOrgList";
import { UOrgViews } from "./UOrgViews";
import { UOrgMaps } from "./UOrgMaps";
import { UOrgIndList } from "./UOrgIndList";
import { UOrgIndView } from "./UOrgIndView";
import { UOrgViewsInti } from "./UOrgViewsInti";
import { UOrganizationMessage } from "./UOrganizationMessage";
import { UOrganizationIndiMessage } from "./UOrganizationIndiMessage";

export const UOrgabizationAllPages = ({ Page, setPage, otherStates }) => {
  const data = {
    list: (
      <UOrgList
        {...{
          Page,
          setPage,
          ...otherStates,
        }}
      />
    ),
    orgviews: (
      <UOrgViews
        {...{
          Page,
          setPage,
          ...otherStates,
        }}
      />
    ),
    orgviewsIndi: (
      <UOrgViewsInti
        {...{
          Page,
          setPage,
          ...otherStates,
        }}
      />
    ),
    orgmaps: (
      <UOrgMaps
        {...{
          Page,
          setPage,
          ...otherStates,
        }}
      />
    ),
    orgindlist: (
      <UOrgIndList
        {...{
          Page,
          setPage,
          ...otherStates,
        }}
      />
    ),
    orgindview: (
      <UOrgIndView
        {...{
          Page,
          setPage,
          ...otherStates,
        }}
      />
    ),
    orgmessage: (
      <UOrganizationMessage
        {...{
          Page,
          setPage,
          ...otherStates,
        }}
      />
    ),
    orgindimessage: (
      <UOrganizationIndiMessage
        {...{
          Page,
          setPage,
          ...otherStates,
        }}
      />
    ),
  };
  return data[Page];
};
