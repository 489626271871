import React, { useEffect } from "react";
import { ViewCardIndividual } from "../../../component/Card/ViewCardIndividual";
import { scrollToTop } from "../../../utils/utils";

export const IndiView = ({ ViewData }) => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <ViewCardIndividual
        {...{
          ViewData,
        }}
      />
    </>
  );
};
