import React from "react";
import { RangeBox } from "../../../component/AnalyticsComp/RangeBox";

export const IndiAnalytics = ({ data: people, FilterObj }) => {
  const mapIdToRange = (id) => {
    if (id >= 18 && id <= 25) return "18 - 25";
    if (id >= 26 && id <= 32) return "26 - 32";
    if (id >= 33 && id <= 54) return "33 - 54";
    if (id >= 55 && id <= 72) return "55 - 72";
    return id;
  };
  return (
    <>
      <RangeBox
        {...{
          title: "Geography",
          type: "(Individual)",
          lastday: FilterObj?.empty[0],
          list: people?.locations?.map((el) => ({
            ...el,
            _id: el.name,
            count: el.total_ind,
          })),
          total: people?.total,
        }}
      />
      <RangeBox
        {...{
          title: "Services seeking",
          type: "(Individual)",
          lastday: FilterObj?.empty[0],
          list: people?.servicesSeeking,
          total: people?.total,
        }}
      />
      <RangeBox
        {...{
          title: "Age",
          type: "(Individual)",
          lastday: FilterObj?.empty[0],
          list: [
            ...people?.ageGroups.map((el, i) => {
              return {
                ...el,
                _id: mapIdToRange(el._id),
              };
            }),
          ],
          total: people.total,
        }}
      />
      <RangeBox
        {...{
          title: "Gender",
          type: "(Individual)",
          lastday: FilterObj?.empty[0],
          list: [...people?.genderGroups],
          total: people.total,
        }}
      />
      <div className="solitary_confinment">
        <div className="solitary_confinment_title">Solitary Confinement</div>
        <div className="solitary_confinment_num">
          Number of Men - {people?.solitaryConfinement[0]?.men[0]?.count || 0}
        </div>
        <div className="solitary_confinment_num">
          Number of Women - {people?.solitaryConfinement[0]?.women[0]?.count || 0}
        </div>
        <div className="solitary_confinment_num">
          Other - {people?.solitaryConfinement[0]?.other[0]?.count || 0}
        </div>
      </div>
    </>
  );
};
