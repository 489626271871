import axios from "axios";
import React, { useEffect, useState } from "react";
import { Inputs } from "../../../component/Input/Inputs";
import { Map, Marker, ZoomControl } from "pigeon-maps";
import { useSelector } from "react-redux";

export const UOrgMaps = ({
  Page,
  setPage,
  Lists,
  setLists,
  View,
  setView,
  FilterObj,
}) => {
  const store = useSelector((state) => state.checktoken_data);
  let userType = store?.user_type;
  userType = localStorage.getItem("userType");
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState({
    lat: 37.929584,
    lng: -95.029606,
  });
  const [Zoom, setZoom] = useState(3.3);
  const [key, setKey] = useState(0);
  const [CurrentUser, setCurrentUser] = useState({});

  const updateCoordinates = () => {
    const data = Lists?.filter((el) => {
      if (el.directlyImpactedOrg || el?.governmentOrg) {
        if (el.dioApproval) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }).filter((el) => el?.name?.toLowerCase() == address.toLowerCase());
    if (data.length > 0) {
      setCoordinates({
        lat: Number(data[0].lat),
        lng: Number(data[0].long),
      });
      setKey((prevKey) => prevKey + 1);
      setCurrentUser({
        ...data[0],
        lat: Number(data[0].lat),
        lng: Number(data[0].long),
        key: `marker-${1}`,
      });
    } else {
      setCurrentUser({});
      setCoordinates({
        lat: 37.929584,
        lng: -95.029606,
      });
      setKey(0);
    }
  };
  const nearlatandlan = () => {
    const locations = Lists?.filter((el) => {
      if (el.directlyImpactedOrg || el.governmentOrg) {
        if (el.dioApproval) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }).map((address, index) => ({
      ...address,
      lat: Number(address.lat),
      lng: Number(address.long),
      key: `marker-${index}`,
    }));
    const NEARBY_OFFSET = 0.0001;
    const updatedLocations = [...locations];

    for (let i = 0; i < updatedLocations.length; i++) {
      for (let j = i + 1; j < updatedLocations.length; j++) {
        if (
          updatedLocations[i].lat === updatedLocations[j].lat &&
          updatedLocations[i].lng === updatedLocations[j].lng
        ) {
          updatedLocations[j] = {
            ...updatedLocations[j],
            lat: updatedLocations[j].lat + NEARBY_OFFSET,
            lng: updatedLocations[j].lng + NEARBY_OFFSET,
          };
        }
      }
    }
    return updatedLocations;
  };

  const markers = nearlatandlan();
  useEffect(() => {
    setCurrentUser({});
  }, [FilterObj.Services]);

  return (
    <div className="uorg_maps_container">
      <div className="uorg_maps_search">
        <div className="uorg_maps_search_inp">
          <Inputs
            {...{
              inpType: "type1",
              attr: {
                class: "search",
                readonly: "",
                value: address,
                id: "city",
                type: "text",
                event: {
                  change: ({ target }) => {
                    setAddress(target.value);
                  },
                  onkeydown: ({ key }) => {
                    if (key == "Enter") {
                      updateCoordinates();
                      if (address.length == 0) {
                        setCoordinates({
                          lat: 37.929584,
                          lng: -95.029606,
                        });
                      }
                    }
                  },
                },
              },
              data: {
                placeholder: "Search",
                prefix: (
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1_6693)">
                      <path
                        d="M17.8292 17.0207L13.1819 12.4477C14.3989 11.1255 15.1466 9.37682 15.1466 7.45261C15.146 3.33639 11.7556 0 7.57302 0C3.39042 0 0 3.33639 0 7.45261C0 11.5688 3.39042 14.9052 7.57302 14.9052C9.3802 14.9052 11.0377 14.2801 12.3397 13.241L17.005 17.832C17.2323 18.056 17.6014 18.056 17.8287 17.832C17.8827 17.7792 17.9257 17.7162 17.9551 17.6465C17.9844 17.5769 17.9996 17.5021 17.9996 17.4265C17.9997 17.3509 17.9846 17.2761 17.9554 17.2064C17.9261 17.1367 17.8832 17.0736 17.8292 17.0207ZM7.57302 13.7586C4.03408 13.7586 1.16522 10.9353 1.16522 7.45261C1.16522 3.96991 4.03408 1.14663 7.57302 1.14663C11.112 1.14663 13.9808 3.96991 13.9808 7.45261C13.9808 10.9353 11.112 13.7586 7.57302 13.7586Z"
                        fill="#D0D0D1"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_6693">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                ),
                type: "",
              },
            }}
          />
        </div>
        <div
          className="uorg_maps_open_list"
          onClick={() => {
            setPage("list");
          }}
        >
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1_2225)">
              <path
                d="M19.5027 17.2623H6.12881C5.99776 17.2623 5.86799 17.2365 5.74691 17.1863C5.62584 17.1361 5.51582 17.0626 5.42315 16.97C5.33048 16.8773 5.25698 16.7673 5.20682 16.6462C5.15667 16.5251 5.13086 16.3954 5.13086 16.2643C5.13086 16.1333 5.15667 16.0035 5.20682 15.8824C5.25698 15.7613 5.33048 15.6513 5.42315 15.5586C5.51582 15.466 5.62584 15.3925 5.74691 15.3423C5.86799 15.2922 5.99776 15.2664 6.12881 15.2664H19.5024C19.7671 15.2663 20.0209 15.3714 20.2081 15.5585C20.3953 15.7457 20.5005 15.9995 20.5005 16.2641C20.5006 16.5288 20.3955 16.7827 20.2083 16.9699C20.0212 17.157 19.7674 17.2622 19.5027 17.2623ZM19.5027 10.9981H6.12881C5.86414 10.9981 5.61031 10.893 5.42315 10.7058C5.236 10.5187 5.13086 10.2648 5.13086 10.0002C5.13086 9.73548 5.236 9.48164 5.42315 9.29449C5.61031 9.10734 5.86414 9.0022 6.12881 9.0022H19.5024C19.7671 9.00215 20.0209 9.10725 20.2081 9.29437C20.3953 9.48149 20.5005 9.73531 20.5005 9.99998C20.5006 10.2647 20.3955 10.5185 20.2083 10.7057C20.0212 10.8929 19.7674 10.9981 19.5027 10.9981ZM19.5027 4.73395H6.12881C5.99776 4.73395 5.86799 4.70813 5.74691 4.65798C5.62584 4.60783 5.51582 4.53432 5.42315 4.44165C5.33048 4.34898 5.25698 4.23897 5.20682 4.11789C5.15667 3.99681 5.13086 3.86704 5.13086 3.73599C5.13086 3.60494 5.15667 3.47517 5.20682 3.35409C5.25698 3.23301 5.33048 3.123 5.42315 3.03033C5.51582 2.93766 5.62584 2.86415 5.74691 2.814C5.86799 2.76385 5.99776 2.73804 6.12881 2.73804H19.5024C19.7671 2.73799 20.0209 2.84309 20.2081 3.03021C20.3953 3.21734 20.5005 3.47115 20.5005 3.73583C20.5006 4.0005 20.3955 4.25435 20.2083 4.44153C20.0212 4.62872 19.7674 4.7339 19.5027 4.73395Z"
                fill="white"
              />
              <path
                d="M1.84025 5.15341C2.58045 5.15341 3.18051 4.55335 3.18051 3.81315C3.18051 3.07295 2.58045 2.4729 1.84025 2.4729C1.10005 2.4729 0.5 3.07295 0.5 3.81315C0.5 4.55335 1.10005 5.15341 1.84025 5.15341Z"
                fill="white"
              />
              <path
                d="M1.84025 11.3404C2.58045 11.3404 3.18051 10.7404 3.18051 10.0002C3.18051 9.25996 2.58045 8.65991 1.84025 8.65991C1.10005 8.65991 0.5 9.25996 0.5 10.0002C0.5 10.7404 1.10005 11.3404 1.84025 11.3404Z"
                fill="white"
              />
              <path
                d="M1.84025 17.5274C2.58045 17.5274 3.18051 16.9274 3.18051 16.1872C3.18051 15.447 2.58045 14.8469 1.84025 14.8469C1.10005 14.8469 0.5 15.447 0.5 16.1872C0.5 16.9274 1.10005 17.5274 1.84025 17.5274Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_2225">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                  transform="translate(0.5)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>

      {Object.keys(CurrentUser)?.length == 0 ? (
        ""
      ) : (
        <div
          className="uorg_maps_curr_box"
          onClick={() => {
            setPage("orgviews");
            setView(CurrentUser);
          }}
        >
          <div
            className="uorg_maps_curr_box_image"
            style={{
              backgroundImage: `url(${
                CurrentUser?.picture || "https://imgur.com/Yjchy3r.png"
              })`,
            }}
          ></div>
          <div className="uorg_maps_curr_detail">
            <div className="uorg_maps_curr_title">{CurrentUser?.name}</div>
            <div className="uorg_maps_curr_address">{CurrentUser?.address}</div>
            <div className="uorg_maps_curr_list">
              {CurrentUser?.servicesOffered?.map((el, i) => (
                <div key={i}>{el}</div>
              ))}
            </div>
            <div className="uorg_maps_curr_next">
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.10265 13.5435H20.4019L16.9727 16.9686C15.9885 17.9529 17.4649 19.4332 18.4491 18.449L21.5712 15.323L23.6578 13.2364C23.8525 13.0408 23.9618 12.7761 23.9618 12.5001C23.9618 12.2242 23.8525 11.9595 23.6578 11.7639L18.4452 6.55133C18.3469 6.45056 18.2293 6.37071 18.0994 6.31658C17.9695 6.26246 17.83 6.23517 17.6893 6.23637C17.4814 6.23691 17.2785 6.29952 17.1064 6.41618C16.9344 6.53283 16.8012 6.6982 16.7238 6.8911C16.6463 7.08399 16.6283 7.29561 16.672 7.49882C16.7156 7.70203 16.819 7.88757 16.9688 8.03164L20.4058 11.4568H2.04753C0.602653 11.5316 0.708952 13.6143 2.10265 13.5435Z"
                  fill="#D0D0D1"
                />
              </svg>
            </div>
          </div>
        </div>
      )}

      <div className="maps_graph">
        <Map
          center={
            CurrentUser.name
              ? [CurrentUser.lat, CurrentUser.lng]
              : [coordinates.lat, coordinates.lng]
          }
          defaultZoom={Zoom}
          key={key}
          animate={true}
          touchEvents={true}
          zoomSnap={false}
          mouseEvents={true}
          onBoundsChanged={({ center, zoom }) => {
            setZoom(zoom);
          }}
        >
          {markers
            // ?.filter((el) => el.dioApproval || !el.directlyImpactedOrg)
            ?.filter((el) => {
              if (el.directlyImpactedOrg || el?.governmentOrg) {
                if (el.dioApproval) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            })
            ?.filter((el) => el.id != store.userdetail.id)
            ?.filter((item) => {
              if (FilterObj["empty"] != "All") {
                return item?.servicesOffered?.some((service) =>
                  FilterObj["empty"]?.includes(service)
                );
              } else {
                return item;
              }
            })
            .map((marker) => (
              <Marker
                width={marker.name == CurrentUser.name ? 70 : 50}
                key={marker.key}
                color={marker.name == CurrentUser.name ? "#313d92" : ""}
                anchor={[marker.lat, marker.lng]}
                onClick={() => {
                  setCurrentUser(marker);
                  setCoordinates({
                    lat: Number(marker.lat),
                    lng: Number(marker.lng),
                  });
                }}
              />
            ))}
        </Map>
      </div>
    </div>
  );
};
