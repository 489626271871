import React from "react";

export const CheckBox = ({ check, onclick }) => {
  return (
    <div
      active={check?.toString()}
      className="checkbox_container"
      onClick={() => {
        onclick();
      }}
    >
      {check ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          xlink="http://www.w3.org/1999/xlink"
          width="13"
          height="13"
          x="0"
          y="0"
          viewBox="0 0 512 512"
          style={{ enableBackground: "new 0 0 512 512" }}
          space="preserve"
        >
          <g>
            <path
              d="M504.502 75.496c-9.997-9.998-26.205-9.998-36.204 0L161.594 382.203 43.702 264.311c-9.997-9.998-26.205-9.997-36.204 0-9.998 9.997-9.998 26.205 0 36.203l135.994 135.992c9.994 9.997 26.214 9.99 36.204 0L504.502 111.7c9.998-9.997 9.997-26.206 0-36.204z"
              fill="#000000"
              opacity="1"
              data-original="#000000"
            ></path>
          </g>
        </svg>
      ) : (
        ""
      )}
    </div>
  );
};
