import React, { useEffect, useState } from "react";
import { ViewCard } from "../../../component/Card/ViewCard";
import { scrollToTop } from "../../../utils/utils";
import { ImageToLink } from "../../../component/Api/api";
import { Inputs } from "../../../component/Input/Inputs";
import { SelectInput } from "../../../component/Input/SelectInput";

export const OrganizationEdit = ({
  ViewData,
  setViewData,
  errpagevalues,
  seterrpagevalues,
}) => {
  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    setViewData((prev) => ({
      ...prev,
      excludeViolenceHistorydummy: prev.excludeViolenceHistory ? "Yes" : "No",
      directlyImpactedOrgdummy: prev.directlyImpactedOrg ? "Yes" : "No",
      excludeSexualOffensesdummy: prev.excludeSexualOffenses ? "Yes" : "No",
    }));
  }, []);
  const formDataImage = (e) => {
    ImageToLink(e.target.files[0], ({ status, response }) => {
      if (status) {
        setViewData((prev) => ({
          ...prev,
          picture: response.url,
        }));
      }
    });
  };

  return (
    <>
      <div className="organization_edit">
        <div className="organization_form_profile_photo">
          <div
            className="user_profile_img"
            style={{
              backgroundImage: `url(${
                ViewData?.picture || "https://imgur.com/Yjchy3r.png"
              })`,
            }}
          ></div>
          <div className="user_profile_svg">
            {[
              {
                name: "Camera",
                type: "image",
                first_color: "#B758E6",
                last_color: "#C562FB",
                multiSelect: false,
                capture: false,

                input: {
                  valids: "",
                },
                input_filter: ["image/*"],
                click: () => {},
              },
            ].map((el, i) => (
              <label key={i} className="img_label" htmlFor={el.name}>
                <input
                  type="file"
                  id={el.name}
                  capture={el.capture}
                  {...{
                    ...(el.input_filter.length
                      ? { accept: el.input_filter }
                      : {}),
                  }}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                  onChange={(e) => {
                    formDataImage(e);
                  }}
                  multiple={el.multiSelect}
                />
                <div>
                  <svg
                    width="23"
                    height="19"
                    viewBox="0 0 23 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.5802 10.7201C14.5802 12.6725 12.992 14.2608 11.0396 14.2608C9.08715 14.2608 7.49949 12.6725 7.49949 10.7201C7.49949 8.76768 9.08715 7.17942 11.0396 7.17942C12.992 7.17942 14.5802 8.76829 14.5802 10.7201ZM22.0166 5.69251V15.7489C22.0166 16.3927 21.7608 17.0102 21.3056 17.4655C20.8503 17.9207 20.2328 18.1765 19.589 18.1765H2.49011C1.84627 18.1765 1.2288 17.9207 0.773531 17.4655C0.318266 17.0102 0.0625 16.3927 0.0625 15.7489V5.69251C0.0625 5.04867 0.318266 4.43119 0.773531 3.97593C1.2288 3.52066 1.84627 3.2649 2.49011 3.2649H5.47608V2.42494C5.47608 1.86158 5.69987 1.32129 6.09823 0.922933C6.49659 0.524576 7.03687 0.300781 7.60024 0.300781H14.4789C15.0422 0.300781 15.5825 0.524576 15.9809 0.922933C16.3792 1.32129 16.603 1.86158 16.603 2.42494V3.26429H19.589C20.9296 3.2649 22.0166 4.35186 22.0166 5.69251ZM16.4009 10.7201C16.4009 7.76387 13.9958 5.35871 11.0396 5.35871C8.08394 5.35871 5.67878 7.76387 5.67878 10.7201C5.67878 13.6763 8.08394 16.0815 11.0396 16.0815C13.9958 16.0815 16.4009 13.6763 16.4009 10.7201Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </label>
            ))}
          </div>
        </div>
        <div>
          <Inputs
            {...{
              inpType: "type1",
              attr: {
                class: "",
                readonly: "",
                type: "text",
                value: ViewData.name,
                error: errpagevalues?.name,
                event: {
                  change: ({ target }) => {
                    setViewData((prev) => ({
                      ...prev,
                      name: target.value,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      name: !target.value,
                    }));
                  },
                },
              },
              data: {
                title: "Name",
                prefix: "",
              },
            }}
          />
        </div>
        <div>
          <Inputs
            {...{
              inpType: "type1",
              attr: {
                class: "",
                readonly: "",
                type: "text",
                value: ViewData.phone,
                error: errpagevalues?.phone,
                event: {
                  change: ({ target }) => {
                    if (!/^\d*$/i.test(target.value)) {
                      return;
                    }
                    const cleanedInput = target.value;
                    if (cleanedInput.length > 10) {
                      return;
                    }
                    setViewData((prev) => ({
                      ...prev,
                      phone: cleanedInput,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      phone: cleanedInput.length != 10,
                    }));
                  },
                },
              },
              data: {
                title: "Phone",
                prefix: "",
              },
            }}
          />
        </div>
        <div>
          <Inputs
            {...{
              inpType: "type1",
              attr: {
                class: "",
                readonly: "",
                type: "text",
                value: ViewData.address,
                error: errpagevalues?.address,
                event: {
                  change: ({ target }) => {
                    setViewData((prev) => ({
                      ...prev,
                      address: target.value,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      address: !target.value,
                    }));
                  },
                },
              },
              data: {
                title: "Address",
                prefix: "",
              },
            }}
          />
        </div>
        <div>
          <Inputs
            {...{
              inpType: "type1",
              attr: {
                class: "",
                readonly: "",
                type: "text",
                value: ViewData.website,
                error: errpagevalues?.website,
                event: {
                  change: ({ target }) => {
                    setViewData((prev) => ({
                      ...prev,
                      website: target.value,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      website: !target.value,
                    }));
                  },
                },
              },
              data: {
                title: "Website",
                prefix: "",
              },
            }}
          />
        </div>
        <div className="no_padd">
          <Inputs
            {...{
              inpType: "type1",
              attr: {
                class: "",
                readonly: "",
                type: "text",
                value: ViewData.contactPersonName,
                error: errpagevalues?.contactPersonName,
                event: {
                  change: ({ target }) => {
                    setViewData((prev) => ({
                      ...prev,
                      contactPersonName: target.value,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      contactPersonName: !target.value,
                    }));
                  },
                },
              },
              data: {
                title: "Contact person",
                prefix: "",
              },
            }}
          />
        </div>

        <div className="sep_user">
          Do you exclude people with sexual offenses?
          <div className="user_btn_list">
            {["Yes", "No"].map((el, i) => (
              <div
                className={
                  "user_btn" +
                  (ViewData.excludeSexualOffensesdummy == el
                    ? " active_btn"
                    : "")
                }
                key={i}
                onClick={() => {
                  setViewData((prev) => ({
                    ...prev,
                    excludeSexualOffensesdummy: el,
                  }));
                  seterrpagevalues((prev) => ({
                    ...prev,
                    excludeSexualOffensesdummy: !el,
                  }));
                }}
              >
                {el}
              </div>
            ))}
          </div>
          <div
            className={
              "user_error" +
              (errpagevalues.excludeSexualOffensesdummy ? " active" : "")
            }
          >
            Select any one
          </div>
        </div>
        <div className="sep_user">
          Do you exclude individuals with a history of violence?
          <div className="user_btn_list">
            {["Yes", "No"].map((el, i) => (
              <div
                className={
                  "user_btn" +
                  (ViewData.excludeViolenceHistorydummy == el
                    ? " active_btn"
                    : "")
                }
                key={i}
                onClick={() => {
                  setViewData((prev) => ({
                    ...prev,
                    excludeViolenceHistorydummy: el,
                  }));
                  seterrpagevalues((prev) => ({
                    ...prev,
                    excludeViolenceHistorydummy: !el,
                  }));
                }}
              >
                {el}
              </div>
            ))}
          </div>
          <div
            className={
              "user_error" +
              (errpagevalues.excludeViolenceHistorydummy ? " active" : "")
            }
          >
            Select any one
          </div>
        </div>
        <div className="sep_user">
          Services offered
          <div className="user_btn_list">
            <SelectInput
              {...{
                inptype: "type1",
                attr: {
                  class: "",
                  type: "",
                  value: ViewData.servicesOffered,
                  error: errpagevalues.servicesOffered,
                  event: {
                    click: (value) => {
                      setViewData((prev) => ({
                        ...prev,
                        servicesOffered: prev.servicesOffered.includes(value)
                          ? prev.servicesOffered.filter((el) => el != value)
                          : [...prev.servicesOffered, value],
                      }));
                      seterrpagevalues((prev) => ({
                        ...prev,
                        servicesOffered: !value,
                      }));
                    },
                  },
                },
                data: {
                  type: "",
                  placeholder: "Ex: Food",
                  list: [
                    "Employment",
                    "Housing",
                    "Food",
                    "Clothing",
                    "Mental Health",
                    "Health/Wellness",
                    "Healthcare",
                    "Reproductive Health",
                    "Skills Training",
                    "Legal Services",
                    "Childcare",
                    "Diapers",
                    "Baby Formula",
                    "Feminine Hygiene Products",
                    "Higher Education",
                    "Support Groups",
                    "Utility Assistance",
                    "Furniture",
                    "Hygiene Products",
                    "Dentist",
                    "Identification",
                    "Transportation",
                    "Financial Services",
                    "Literacy",
                    "Insurance",
                    "Reentry Program",
                    "Voting Rights",
                    "Probation Reform GA",
                    "Medical",
                    "Carpentry",
                  ],
                  bottomnav: false,
                },
              }}
            />
          </div>
        </div>
        <div className="sep_user">
          <div className="user_desc">Describe your organization</div>
          <Inputs
            {...{
              inpType: "type1",
              attr: {
                class: "",
                readonly: "",
                type: "text",
                id: "orgdescription",
                value: ViewData.description,
                error: errpagevalues?.description,
                event: {
                  change: ({ target }) => {
                    setViewData((prev) => ({
                      ...prev,
                      description: target.value,
                    }));
                    seterrpagevalues((prev) => ({
                      ...prev,
                      description: !target.value,
                    }));
                  },
                },
              },
              data: {
                title: "",
                prefix: "",
                type: "textarea",
              },
            }}
          />
        </div>
      </div>
    </>
  );
};
