import React, { useEffect } from "react";
import { scrollToTop } from "../../../utils/utils";
import { ChatNewMessage, CreateChat, token } from "../../../component/Api/api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const UOrgViews = ({
  Page,
  setPage,
  Lists,
  setLists,
  View,
  setView,
  setloading,
}) => {
  const current_store = useSelector((state) => state.checktoken_data);
  const nav = useNavigate();
  useEffect(() => {
    scrollToTop();
  }, []);
  const send_profile = () => {
    setloading(true);
    CreateChat(
      {
        sender: current_store?.userdetail?.id,
        receiver: View.id,
      },
      token,
      ({ status, response }) => {
        const res = response;
        if (response.status) {
          ChatNewMessage(
            {
              chatId: response?.chat?.chat?._id,
              sender: current_store?.userdetail?.id,
              message: JSON.stringify(current_store?.userdetail),
              messageType: "profile",
              repliedTo: "",
            },
            token,
            ({ status, response }) => {
              if (response.status) {
                nav(`/message?${res?.chat?.chat?._id}`);
              }
            }
          );
        }
      }
    );
  };
  return (
    <div className="uorg_views_section">
      <img src={View?.picture || "https://imgur.com/Yjchy3r.png"} />
      <div className="uorg_views_title">{View?.name}</div>
      <div className="uorg_views_type">{View?.type}</div>
      <div className="uorg_views_title1">Organization Name</div>
      <div className="uorg_views_desc">{View?.name}</div>

      <div className="uorg_views_title1">Phone</div>
      <div className="uorg_views_desc">{View?.phone}</div>
      <div className="uorg_views_title1">Email</div>
      <div className="uorg_views_desc">{View?.email}</div>
      <div className="uorg_views_title1">Address</div>
      <div className="uorg_views_desc">{View?.address}</div>

      <div className="uorg_views_title1">Website</div>
      <div className="uorg_views_desc">{View?.website}</div>
      <div className="uorg_views_title1">Contact Person Name</div>
      <div className="uorg_views_desc">{View?.contactPersonName}</div>

      <div className="uorg_views_title1">
        Is this a directly impacted organization?
      </div>
      <div className="uorg_views_desc">
        {View?.directlyImpactedOrg ? "Yes" : "No"}
      </div>
      <div className="uorg_views_title1">Is this a government entity?</div>
      <div className="uorg_views_desc">
        {View?.governmentOrg ? "Yes" : "No"}
      </div>
      <div className="uorg_views_title1">
        Do you exclude individuals with a history of violence?
      </div>
      <div className="uorg_views_desc">
        {View?.excludeViolenceHistory ? "Yes" : "No"}
      </div>
      <div className="uorg_views_title1">
        Do you exclude people with sexual offenses?
      </div>
      <div className="uorg_views_desc">
        {View?.excludeSexualOffenses ? "Yes" : "No"}
      </div>
      <div className="uorg_views_title1">Services offered</div>
      <div className="uorg_views_list">
        {View?.servicesOffered?.map((el, i) => (
          <div key={i}>{el}</div>
        ))}
      </div>
      <div className="uorg_views_title1">About us</div>
      <div className="uorg_views_desc">{View?.description}</div>
      {current_store.user_type == "individual" ? (
        <div
          className="uorg_views_btns"
          onClick={() => {
            send_profile();
          }}
        >
          <svg
            width="23"
            height="24"
            viewBox="0 0 23 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.4994 12.8024C12.6809 12.8024 13.8359 12.452 14.8183 11.7956C15.8007 11.1392 16.5664 10.2062 17.0186 9.11459C17.4707 8.02299 17.589 6.82183 17.3585 5.66299C17.128 4.50416 16.5591 3.4397 15.7236 2.60423C14.8881 1.76875 13.8236 1.19979 12.6648 0.969283C11.506 0.738776 10.3048 0.85708 9.21321 1.30924C8.12162 1.76139 7.18861 2.52709 6.53219 3.5095C5.87576 4.49191 5.52539 5.64692 5.52539 6.82845C5.52723 8.41228 6.15721 9.93072 7.27715 11.0507C8.39709 12.1706 9.91552 12.8006 11.4994 12.8024ZM11.4994 2.01074C12.4522 2.01074 13.3837 2.2933 14.1759 2.82268C14.9682 3.35205 15.5857 4.10448 15.9503 4.9848C16.315 5.86512 16.4104 6.8338 16.2245 7.76834C16.0386 8.70289 15.5798 9.56132 14.906 10.2351C14.2322 10.9089 13.3738 11.3677 12.4392 11.5536C11.5047 11.7395 10.536 11.6441 9.65569 11.2794C8.77537 10.9148 8.02295 10.2973 7.49357 9.50503C6.9642 8.71276 6.68164 7.78131 6.68164 6.82845C6.68307 5.55116 7.19111 4.32658 8.09429 3.4234C8.99748 2.52021 10.2221 2.01217 11.4994 2.01074ZM22.0983 23.016C22.0957 20.6146 21.1406 18.3124 19.4426 16.6144C17.7446 14.9164 15.4423 13.9613 13.041 13.9587H9.95768C8.76809 13.9581 7.59004 14.1919 6.49089 14.6469C5.39173 15.1018 4.39302 15.769 3.55186 16.6101C2.71069 17.4513 2.04355 18.45 1.5886 19.5492C1.13364 20.6483 0.899784 21.8264 0.900392 23.016V23.5941H22.0983V23.016ZM2.07745 22.4378C2.2224 20.4473 3.11571 18.5855 4.5777 17.2269C6.03969 15.8683 7.96189 15.1137 9.95768 15.1149H13.041C15.0358 15.1172 16.9561 15.8729 18.4174 17.2308C19.8786 18.5887 20.7729 20.4486 20.9212 22.4378H2.07745Z"
              fill="white"
            />
            <path
              d="M9.95776 13.8087C8.74846 13.808 7.55089 14.0458 6.43352 14.5083C5.31615 14.9708 4.3009 15.649 3.44579 16.5041C2.59068 17.3592 1.9125 18.3744 1.45 19.4918C0.987517 20.6091 0.749784 21.8067 0.750392 23.016C0.750392 23.016 0.750392 23.016 0.750392 23.016L0.750392 23.5941V23.7441H0.900391H22.0983H22.2483V23.5941V23.016V23.0158C22.2456 20.5747 21.2747 18.2344 19.5486 16.5083C17.8225 14.7823 15.4822 13.8114 13.0412 13.8087H13.041H9.95776ZM9.95776 13.8087L9.95768 13.9587V13.8087H9.95776ZM11.4992 12.9524H11.4994C12.7106 12.9524 13.8946 12.5932 14.9016 11.9203C15.9087 11.2474 16.6936 10.291 17.1572 9.17199C17.6207 8.05298 17.7419 6.82166 17.5056 5.63373C17.2693 4.4458 16.6861 3.35461 15.8296 2.49816C14.9732 1.64171 13.882 1.05846 12.6941 0.822165C11.5061 0.585871 10.2748 0.707145 9.15581 1.17065C8.03681 1.63416 7.08037 2.41908 6.40746 3.42616C5.73456 4.43324 5.37539 5.61725 5.37539 6.82845V6.82863C5.37727 8.45218 6.02306 10.0087 7.17109 11.1567C8.31911 12.3047 9.87563 12.9505 11.4992 12.9524ZM9.9576 15.2649H9.95768H13.0408C14.9978 15.2671 16.8817 16.0086 18.3153 17.3407C19.7093 18.6361 20.5774 20.3972 20.758 22.2878H2.24046C2.4179 20.3959 3.28506 18.6329 4.67981 17.3368C6.11403 16.004 7.99972 15.2638 9.9576 15.2649ZM11.4994 2.16074C12.4226 2.16076 13.325 2.43451 14.0926 2.9474C14.8602 3.46029 15.4585 4.18929 15.8118 5.0422C16.165 5.89511 16.2575 6.83363 16.0774 7.73908C15.8973 8.64453 15.4527 9.47623 14.7999 10.129C14.1471 10.7818 13.3154 11.2264 12.41 11.4065C11.5045 11.5866 10.566 11.4941 9.7131 11.1409C8.86018 10.7876 8.13119 10.1893 7.61829 9.42169C7.10543 8.65414 6.83167 7.75175 6.83164 6.82862C6.83303 5.59105 7.32526 4.40456 8.20036 3.52946C9.07543 2.65439 10.2619 2.16216 11.4994 2.16074Z"
              stroke="white"
              stroke-width="0.3"
            />
          </svg>
          Send profile to Organization
        </div>
      ) : (
        ""
      )}
      <div
        className="uorg_views_btns"
        onClick={() => {
          setloading(true);
          CreateChat(
            {
              sender: current_store?.userdetail?.id,
              receiver: View.id,
            },
            token,
            ({ status, response }) => {
              if (response.status) {
                nav(`/message?${response?.chat?.chat?._id}`);
              }
            }
          );
        }}
      >
        <svg
          width="25"
          height="25"
          viewBox="0 0 28 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 27.1875C6.76801 27.1873 6.54558 27.0917 6.38154 26.9218C6.21749 26.7519 6.12523 26.5215 6.125 26.2812V21.75H3.5C2.05275 21.75 0.875 20.5302 0.875 19.0312V4.53125C0.875 3.03231 2.05275 1.8125 3.5 1.8125H24.5C25.9473 1.8125 27.125 3.03231 27.125 4.53125V19.0312C27.125 20.5302 25.9473 21.75 24.5 21.75H13.8696L7.54688 26.989C7.39171 27.1177 7.19879 27.1877 7 27.1875ZM3.5 3.625C3.017 3.625 2.625 4.03191 2.625 4.53125V19.0312C2.625 19.5306 3.017 19.9375 3.5 19.9375H7C7.23206 19.9375 7.45462 20.033 7.61872 20.2029C7.78281 20.3729 7.875 20.6034 7.875 20.8438V24.3962L13.0156 20.136C13.1709 20.0074 13.3637 19.9374 13.5625 19.9375H24.5C24.983 19.9375 25.375 19.5306 25.375 19.0312V4.53125C25.375 4.03191 24.983 3.625 24.5 3.625H3.5Z"
            fill="white"
          ></path>
        </svg>
        Start Chatting
      </div>
    </div>
  );
};
