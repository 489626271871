import React from "react";

export const UOrgIndView = ({
  Page,
  setPage,
  Lists,
  setLists,
  View,
  setView,
}) => {
  return (
    <div className="uorg_ind_view_section">
      <img src="https://imgur.com/O8TrUKL.jpeg" />
      <div className="uorg_ind_title">{View.name}</div>
      <div className="uorg_ind_type">Organization</div>
      <div className="uorg_ind_title1">Phone</div>
      <div className="uorg_ind_desc">8920138910</div>
      <div className="uorg_ind_title1">Website</div>
      <div className="uorg_ind_desc">Mondaysolutions.com</div>
      <div className="uorg_ind_title1">Contact person</div>
      <div className="uorg_ind_desc">Marco Borin</div>
      <div className="uorg_ind_title1">What services are you seeking?</div>
      <div className="uorg_ind_desc">Marco Borin</div>
      <div className="uorg_ind_title1">What services are you seeking?</div>
      <div className="uorg_ind_desc">
        Horem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
      </div>
      <div className="uorg_ind_title1">Services offered</div>
      <div className="uorg_ind_list">
        {View?.serviceoffer?.map((el, i) => (
          <div key={i}>{el}</div>
        ))}
      </div>
      <div
        className="uorg_ind_btns"
        onClick={() => {
          setPage("orgindimessage");
        }}
      >
        <svg
          width="29"
          height="29"
          viewBox="0 0 28 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 27.1875C6.76801 27.1873 6.54558 27.0917 6.38154 26.9218C6.21749 26.7519 6.12523 26.5215 6.125 26.2812V21.75H3.5C2.05275 21.75 0.875 20.5302 0.875 19.0312V4.53125C0.875 3.03231 2.05275 1.8125 3.5 1.8125H24.5C25.9473 1.8125 27.125 3.03231 27.125 4.53125V19.0312C27.125 20.5302 25.9473 21.75 24.5 21.75H13.8696L7.54688 26.989C7.39171 27.1177 7.19879 27.1877 7 27.1875ZM3.5 3.625C3.017 3.625 2.625 4.03191 2.625 4.53125V19.0312C2.625 19.5306 3.017 19.9375 3.5 19.9375H7C7.23206 19.9375 7.45462 20.033 7.61872 20.2029C7.78281 20.3729 7.875 20.6034 7.875 20.8438V24.3962L13.0156 20.136C13.1709 20.0074 13.3637 19.9374 13.5625 19.9375H24.5C24.983 19.9375 25.375 19.5306 25.375 19.0312V4.53125C25.375 4.03191 24.983 3.625 24.5 3.625H3.5Z"
            fill="white"
          ></path>
        </svg>
        Start Chatting
      </div>
    </div>
  );
};
