import React, { useEffect, useState } from "react";
import { ReactComponent as GroupSvg } from "../../../image/groupdata.svg";
import { ReactComponent as Individual } from "../../../image/individual.svg";
import { ReactComponent as Organization } from "../../../image/organization.svg";
import { useNavigate } from "react-router-dom";
import { CheckToken, token, UpdateUserType } from "../../../component/Api/api";
import { useDispatch, useSelector } from "react-redux";
export const WelcomePage = ({ setloading }) => {
  const current_store = useSelector((state) => state);
  const token = localStorage.getItem("user_token");
  const nav = useNavigate();
  const dispatch = useDispatch();

  const [OpenChooser, setOpenChooser] = useState(false);
  useEffect(() => {
    if (current_store?.checktoken_data?.token || token) {
      setloading(true);
      CheckToken(
        current_store?.checktoken_data?.token || token,
        ({ status, response }) => {
          if (response.status) {
            setloading(false);
            dispatch({
              type: "setCheckToken",
              payload: {
                token: current_store?.checktoken_data?.token || token,
                userdetail: response.user,
              },
            });
          } else {
            setloading(false);
            nav("/login");
          }
        }
      );
    } else {
      setloading(false);
      nav("/login");
    }
  }, []);
  return (
    <>
      {OpenChooser ? (
        <div className="choose_page">
          <div className="choose_page_title">Choose Your Profile</div>
          <div
            className="choose_page_choose1"
            onClick={() => {
              localStorage.setItem("userType", "individual");
              dispatch({
                type: "setCheckToken",
                payload: {
                  user_type: "individual",
                },
              });
              nav("/individualform");
            }}
          >
            <div className="choose_image">
              <Individual />
            </div>
            <div className="choose_page_choose1_title">Individual</div>
          </div>
          <div className="choose_page_or">
            <div className="line_or"></div>
            or
            <div className="line_or"></div>
          </div>
          <div
            className="choose_page_choose1"
            onClick={() => {
              localStorage.setItem("userType", "organization");
              dispatch({
                type: "setCheckToken",
                payload: {
                  user_type: "organization",
                },
              });
              nav("/organizationfrom");
            }}
          >
            <div className="choose_image">
              <Organization />
            </div>
            <div className="choose_page_choose1_title">Organization</div>
          </div>
        </div>
      ) : (
        <div className="welcome_page">
          <div className="welcome_page_svg">
            <GroupSvg />
          </div>
          <div className="welcome_page_title">Welcome to ACC=SS</div>
          <div className="welcome_page_desc">
            Active Community Connections Equal Safe Solutions for marginalized
            communities including formerly incarcerated individuals and veterans
          </div>
          <div
            className="welcome_page_btn"
            onClick={() => {
              setOpenChooser(true);
            }}
          >
            <svg
              width="23"
              height="24"
              viewBox="0 0 23 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.4994 12.8024C12.6809 12.8024 13.8359 12.452 14.8183 11.7956C15.8007 11.1392 16.5664 10.2062 17.0186 9.11459C17.4707 8.02299 17.589 6.82183 17.3585 5.66299C17.128 4.50416 16.5591 3.4397 15.7236 2.60423C14.8881 1.76875 13.8236 1.19979 12.6648 0.969283C11.506 0.738776 10.3048 0.85708 9.21321 1.30924C8.12162 1.76139 7.18861 2.52709 6.53219 3.5095C5.87576 4.49191 5.52539 5.64692 5.52539 6.82845C5.52723 8.41228 6.15721 9.93072 7.27715 11.0507C8.39709 12.1706 9.91552 12.8006 11.4994 12.8024ZM11.4994 2.01074C12.4522 2.01074 13.3837 2.2933 14.1759 2.82268C14.9682 3.35205 15.5857 4.10448 15.9503 4.9848C16.315 5.86512 16.4104 6.8338 16.2245 7.76834C16.0386 8.70289 15.5798 9.56132 14.906 10.2351C14.2322 10.9089 13.3738 11.3677 12.4392 11.5536C11.5047 11.7395 10.536 11.6441 9.65569 11.2794C8.77537 10.9148 8.02295 10.2973 7.49357 9.50503C6.9642 8.71276 6.68164 7.78131 6.68164 6.82845C6.68307 5.55116 7.19111 4.32658 8.09429 3.4234C8.99748 2.52021 10.2221 2.01217 11.4994 2.01074ZM22.0983 23.016C22.0957 20.6146 21.1406 18.3124 19.4426 16.6144C17.7446 14.9164 15.4423 13.9613 13.041 13.9587H9.95768C8.76809 13.9581 7.59004 14.1919 6.49089 14.6469C5.39173 15.1018 4.39302 15.769 3.55186 16.6101C2.71069 17.4513 2.04355 18.45 1.5886 19.5492C1.13364 20.6483 0.899784 21.8264 0.900392 23.016V23.5941H22.0983V23.016ZM2.07745 22.4378C2.2224 20.4473 3.11571 18.5855 4.5777 17.2269C6.03969 15.8683 7.96189 15.1137 9.95768 15.1149H13.041C15.0358 15.1172 16.9561 15.8729 18.4174 17.2308C19.8786 18.5887 20.7729 20.4486 20.9212 22.4378H2.07745Z"
                fill="white"
              />
              <path
                d="M9.95776 13.8087C8.74846 13.808 7.55089 14.0458 6.43352 14.5083C5.31615 14.9708 4.3009 15.649 3.44579 16.5041C2.59068 17.3592 1.9125 18.3744 1.45 19.4918C0.987517 20.6091 0.749784 21.8067 0.750392 23.016C0.750392 23.016 0.750392 23.016 0.750392 23.016L0.750392 23.5941V23.7441H0.900391H22.0983H22.2483V23.5941V23.016V23.0158C22.2456 20.5747 21.2747 18.2344 19.5486 16.5083C17.8225 14.7823 15.4822 13.8114 13.0412 13.8087H13.041H9.95776ZM9.95776 13.8087L9.95768 13.9587V13.8087H9.95776ZM11.4992 12.9524H11.4994C12.7106 12.9524 13.8946 12.5932 14.9016 11.9203C15.9087 11.2474 16.6936 10.291 17.1572 9.17199C17.6207 8.05298 17.7419 6.82166 17.5056 5.63373C17.2693 4.4458 16.6861 3.35461 15.8296 2.49816C14.9732 1.64171 13.882 1.05846 12.6941 0.822165C11.5061 0.585871 10.2748 0.707145 9.15581 1.17065C8.03681 1.63416 7.08037 2.41908 6.40746 3.42616C5.73456 4.43324 5.37539 5.61725 5.37539 6.82845V6.82863C5.37727 8.45218 6.02306 10.0087 7.17109 11.1567C8.31911 12.3047 9.87563 12.9505 11.4992 12.9524ZM9.9576 15.2649H9.95768H13.0408C14.9978 15.2671 16.8817 16.0086 18.3153 17.3407C19.7093 18.6361 20.5774 20.3972 20.758 22.2878H2.24046C2.4179 20.3959 3.28506 18.6329 4.67981 17.3368C6.11403 16.004 7.99972 15.2638 9.9576 15.2649ZM11.4994 2.16074C12.4226 2.16076 13.325 2.43451 14.0926 2.9474C14.8602 3.46029 15.4585 4.18929 15.8118 5.0422C16.165 5.89511 16.2575 6.83363 16.0774 7.73908C15.8973 8.64453 15.4527 9.47623 14.7999 10.129C14.1471 10.7818 13.3154 11.2264 12.41 11.4065C11.5045 11.5866 10.566 11.4941 9.7131 11.1409C8.86018 10.7876 8.13119 10.1893 7.61829 9.42169C7.10543 8.65414 6.83167 7.75175 6.83164 6.82862C6.83303 5.59105 7.32526 4.40456 8.20036 3.52946C9.07543 2.65439 10.2619 2.16216 11.4994 2.16074Z"
                stroke="white"
                stroke-width="0.3"
              />
            </svg>
            Create Profile
          </div>
          <div
            className="welcome_page_btn_logout"
            onClick={() => {
              localStorage.clear();
              dispatch({
                type: "setCheckToken",
                payload: {
                  token: "",
                  userdetail: "",
                },
              });
              nav("/login");
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1_5545)">
                <path
                  d="M17.0314 22.875C17.0314 23.1734 16.9129 23.4595 16.7019 23.6705C16.4909 23.8815 16.2048 24 15.9064 24H5.30078C4.30656 23.9989 3.35338 23.6034 2.65036 22.9004C1.94734 22.1974 1.5519 21.2442 1.55078 20.25V3.75C1.5519 2.75578 1.94734 1.8026 2.65036 1.09958C3.35338 0.396563 4.30656 0.00111661 5.30078 0L15.9064 0C16.2048 0 16.4909 0.118526 16.7019 0.329505C16.9129 0.540483 17.0314 0.826631 17.0314 1.125C17.0314 1.42337 16.9129 1.70952 16.7019 1.9205C16.4909 2.13147 16.2048 2.25 15.9064 2.25H5.30078C4.90309 2.25043 4.52181 2.40861 4.2406 2.68982C3.95939 2.97103 3.80122 3.35231 3.80078 3.75V20.25C3.8012 20.6477 3.95937 21.029 4.24059 21.3102C4.5218 21.5914 4.90309 21.7496 5.30078 21.75H15.9064C16.2048 21.75 16.4909 21.8685 16.7019 22.0795C16.9129 22.2905 17.0314 22.5766 17.0314 22.875ZM22.1198 11.2045L16.7737 5.85834C16.6696 5.75196 16.5455 5.66728 16.4085 5.60919C16.2715 5.55111 16.1244 5.52078 15.9756 5.51995C15.8268 5.51913 15.6793 5.54783 15.5417 5.60439C15.4041 5.66096 15.279 5.74426 15.1738 5.84949C15.0686 5.95471 14.9853 6.07975 14.9288 6.21739C14.8722 6.35502 14.8435 6.5025 14.8444 6.6513C14.8452 6.80009 14.8756 6.94724 14.9337 7.08422C14.9918 7.22121 15.0765 7.3453 15.1829 7.44933L18.6084 10.875H9.75198C9.45362 10.875 9.16747 10.9935 8.95649 11.2045C8.74551 11.4155 8.62699 11.7016 8.62699 12C8.62699 12.2984 8.74551 12.5845 8.95649 12.7955C9.16747 13.0065 9.45362 13.125 9.75198 13.125H18.6083L15.1827 16.5506C15.0778 16.655 14.9944 16.779 14.9375 16.9156C14.8806 17.0523 14.8512 17.1988 14.851 17.3468C14.8508 17.4947 14.8798 17.6413 14.9364 17.7781C14.9929 17.9149 15.0759 18.0391 15.1805 18.1438C15.2852 18.2484 15.4094 18.3314 15.5462 18.3879C15.683 18.4445 15.8296 18.4735 15.9775 18.4733C16.1255 18.4731 16.272 18.4437 16.4087 18.3868C16.5453 18.3299 16.6693 18.2465 16.7737 18.1416L22.1198 12.7955C22.3308 12.5845 22.4493 12.2984 22.4493 12C22.4493 11.7017 22.3308 11.4155 22.1198 11.2045Z"
                  fill="#D0D0D1"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_5545">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Logout
          </div>
        </div>
      )}
    </>
  );
};
