import { useState } from "react";
import { Outlet, useRoutes } from "react-router-dom";
import store from "./store/store";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Provider } from "react-redux";
import { ToDefault } from "./pages/ToDefault";
import { Login } from "./pages/User/Login/Login";
import { Dashboard } from "./pages/Admin/Dashboard/Dashboard";
import { AboutUs } from "./pages/Admin/AboutUs/AboutUs";
import { PrivacyPolicy } from "./pages/Admin/PrivacyPolicy/PrivacyPolicy";
import { Faqs } from "./pages/Admin/Faqs/Faqs";
import { Plans } from "./pages/Admin/Plans/Plans";
import { AdminList } from "./pages/Admin/AdminList/AdminList";
import { Message } from "./pages/Admin/Message/Message";
import { Organization } from "./pages/Admin/Organization/Organization";
import { Individual } from "./pages/Admin/Infividual/Individual";
import { PendingRequests } from "./pages/Admin/PendingRequests/PendingRequests";
import { WelcomePage } from "./pages/User/Welcome/WelcomePage";
import { IndividualForm } from "./pages/User/Welcome/IndividualForm";
import { OrganizationForm } from "./pages/User/Welcome/OrganizationForm";
import { Loader } from "./component/Loader/Loader";
import { RequestProcessing } from "./pages/User/Welcome/RequestProcessing";
import { UOrganization } from "./pages/User/Organization/UOrganization";
import { UIndividual } from "./pages/User/Individual/UIndividual";
import { UProfile } from "./pages/User/Profile/UProfile";
import { UMessage } from "./pages/User/Message/UMessage";
import { UAboutUs } from "./pages/User/AboutUs/UAboutUs";
import { UPrivacyPolicy } from "./pages/User/PrivacyPloicy/UPrivacyPolicy";
import { UFaqs } from "./pages/User/Faqs/UFaqs";
import { Support } from "./pages/User/Support/Support";
import { TermsAndCondition } from "./pages/Admin/TermandCondtion/TermsAndCondition";
import { UTermsAndCondition } from "./pages/User/TermsandCondition/UTermsAndCondition";
import { FeedBack } from "./pages/Admin/FeedBack/FeedBack";
import { CheckOut } from "./pages/CheckOut";
import { NoPage } from "./pages/NoPage";

function App() {
  const [loading, setloading] = useState(false);
  const routes = [
    {
      path: "/",
      element: <ToDefault setloading={setloading} />,
    },
    {
      path: "/login",
      element: <Login setloading={setloading} />,
    },
    {
      path: "/welcome",
      element: <WelcomePage setloading={setloading} />,
    },
    {
      path: "/organizationfrom",
      element: <OrganizationForm setloading={setloading} />,
    },
    {
      path: "/individualform",
      element: <IndividualForm setloading={setloading} />,
    },
    {
      path: "/requestprocessing",
      element: <RequestProcessing setloading={setloading} />,
    },
    {
      path: "/aboutus",
      element: <UAboutUs setloading={setloading} />,
    },
    {
      path: "/privacypolicy",
      element: <UPrivacyPolicy setloading={setloading} />,
    },
    {
      path: "/termsandcondition",
      element: <UTermsAndCondition setloading={setloading} />,
    },
    {
      path: "/faqs",
      element: <UFaqs setloading={setloading} />,
    },
    {
      path: "/organization",
      element: <UOrganization setloading={setloading} />,
    },
    {
      path: "/individuals",
      element: <UIndividual setloading={setloading} />,
    },
    {
      path: "/message",
      element: <UMessage setloading={setloading} />,
    },
    {
      path: "/profile",
      element: <UProfile setloading={setloading} />,
    },
    {
      path: "/support",
      element: <Support setloading={setloading} />,
    },
    {
      path: "/checkout",
      element: <CheckOut setloading={setloading} />,
    },
    {
      path: "/admin/",
      element: <Outlet />,
      children: [
        {
          path: "",
          element: <ToDefault {...{ setloading }} />,
        },
        {
          path: "dashboard",
          element: <Dashboard {...{ setloading }} />,
        },
        {
          path: "organization",
          element: <Organization {...{ setloading }} />,
        },
        {
          path: "individuals",
          element: <Individual {...{ setloading }} />,
        },
        {
          path: "pendingrequests",
          element: <PendingRequests {...{ setloading }} />,
        },
        {
          path: "aboutus",
          element: <AboutUs {...{ setloading }} />,
        },
        {
          path: "privacypolicy",
          element: <PrivacyPolicy {...{ setloading }} />,
        },
        {
          path: "termsandcondition",
          element: <TermsAndCondition {...{ setloading }} />,
        },
        {
          path: "faqs",
          element: <Faqs {...{ setloading }} />,
        },
        {
          path: "plans",
          element: <Plans {...{ setloading }} />,
        },
        {
          path: "admin",
          element: <AdminList {...{ setloading }} />,
        },
        {
          path: "feedbacks",
          element: <FeedBack {...{ setloading }} />,
        },
        {
          path: "messages",
          element: <Message {...{ setloading }} />,
        },
      ],
    },
    {
      path: "/*",
      element: <NoPage {...{ setloading }} />,
    },
  ];
  const route = useRoutes(routes);

  return (
    <>
      <Provider store={store}>
        {loading ? <Loader {...{ loading }} /> : ""}
        {route}
      </Provider>
    </>
  );
}

export default App;
