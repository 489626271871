import React, { useEffect, useState } from "react";
import { Inputs } from "../../../component/Input/Inputs";
import { useSelector } from "react-redux";
import { scrollToTop } from "../../../utils/utils";

export const UOrgList = ({
  Page,
  setPage,
  Lists,
  setLists,
  View,
  setView,
  FilterObj,
}) => {
  const store = useSelector((state) => state.checktoken_data);
  const [Search, setSearch] = useState("");
  let userType = store.user_type;
  userType = localStorage.getItem("userType");
  const t_Array = Lists?.sort(
    (b, a) => new Date(a.createdAt) - new Date(b.createdAt)
  )
    // ?.filter(
    //   (el) => el.dioApproval || !el.directlyImpactedOrg || !el.governmentOrg
    // )
    ?.filter((el) => {
      if (el.directlyImpactedOrg || el?.governmentOrg) {
        if (el.dioApproval) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    })
    ?.filter((el) => el.id != store.userdetail.id)
    .filter((el) =>
      Object.values(el).join(",").toLowerCase().match(Search.toLowerCase())
    )
    ?.filter((item) => {
      if (FilterObj["empty"] != "All") {
        return item?.servicesOffered?.some((service) =>
          FilterObj["empty"]?.includes(service)
        );
      } else {
        return item;
      }
    });
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="uorg_list_section">
      <div className="uorg_list_input">
        <Inputs
          {...{
            inpType: "type1",
            attr: {
              class: "search",
              readonly: "",
              value: Search,
              id: "search",
              type: "text",
              event: {
                change: ({ target }) => {
                  if (
                    /^[a-zA-Z0-9\s#@]+$/.test(target.value) ||
                    target.value == ""
                  ) {
                    setSearch(target.value);
                  }
                },
              },
            },
            data: {
              placeholder: "Search",
              prefix: (
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1_6693)">
                    <path
                      d="M17.8292 17.0207L13.1819 12.4477C14.3989 11.1255 15.1466 9.37682 15.1466 7.45261C15.146 3.33639 11.7556 0 7.57302 0C3.39042 0 0 3.33639 0 7.45261C0 11.5688 3.39042 14.9052 7.57302 14.9052C9.3802 14.9052 11.0377 14.2801 12.3397 13.241L17.005 17.832C17.2323 18.056 17.6014 18.056 17.8287 17.832C17.8827 17.7792 17.9257 17.7162 17.9551 17.6465C17.9844 17.5769 17.9996 17.5021 17.9996 17.4265C17.9997 17.3509 17.9846 17.2761 17.9554 17.2064C17.9261 17.1367 17.8832 17.0736 17.8292 17.0207ZM7.57302 13.7586C4.03408 13.7586 1.16522 10.9353 1.16522 7.45261C1.16522 3.96991 4.03408 1.14663 7.57302 1.14663C11.112 1.14663 13.9808 3.96991 13.9808 7.45261C13.9808 10.9353 11.112 13.7586 7.57302 13.7586Z"
                      fill="#D0D0D1"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_6693">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              ),
              type: "",
            },
          }}
        />
        <div
          className="location_open"
          onClick={() => {
            setPage("orgmaps");
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0001 0C6.00613 0 2.75684 3.2493 2.75684 7.2432C2.75684 12.1998 9.23883 19.4763 9.5148 19.7836C9.77402 20.0723 10.2266 20.0718 10.4854 19.7836C10.7613 19.4763 17.2433 12.1998 17.2433 7.2432C17.2432 3.2493 13.994 0 10.0001 0ZM10.0001 10.8875C7.99062 10.8875 6.35586 9.25266 6.35586 7.2432C6.35586 5.23375 7.99066 3.59898 10.0001 3.59898C12.0095 3.59898 13.6443 5.23379 13.6443 7.24324C13.6443 9.2527 12.0095 10.8875 10.0001 10.8875Z"
              fill="white"
            />
          </svg>
        </div>
      </div>

      {t_Array.length == 0 ? (
        <div className="uorg_nodata">
          <div className="p_svg">
            <svg
              width="50"
              height="50"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1_5324)">
                <path
                  d="M15.458 25.1566V9.95841L25.1705 9.94707V26.4562L27 25.4995L27.0295 9.01597C27.0295 8.5086 26.6056 8.08593 26.0989 8.08593L14.5274 8.09944C14.0145 8.10006 13.599 8.51622 13.599 9.02948V25.1566H3.82949V6.75298L13.599 4.12482V6.23631H15.458V2.91172C15.458 2.31003 14.8686 1.85719 14.2871 2.01359L2.65864 5.14181C2.46114 5.195 2.28668 5.31182 2.16228 5.47419C2.03788 5.63655 1.97048 5.8354 1.97051 6.03994V25.1566H0V27.0166H29V25.1566H15.458Z"
                  fill="white"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_1_5324">
                  <rect width="29" height="29" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="p_text">No Organizations</div>
        </div>
      ) : (
        <div className="uorg_list">
          {t_Array?.map((el, i) => (
            <div
              className="uorg_box"
              key={i}
              onClick={() => {
                if (userType == "individual") {
                  setView(el);
                  setPage("orgviews");
                } else {
                  setView(el);
                  setPage("orgviews");
                }
              }}
            >
              <div
                className="uorg_box_img"
                style={{
                  backgroundImage: `url(${
                    el?.picture || "https://imgur.com/Yjchy3r.png"
                  })`,
                }}
              ></div>
              <div className="uorg_box_detail">
                <div className="uorg_box_detail_name">{el?.name}</div>
                <div className="uorg_box_detail_desc">{el?.description}</div>
                <div className="uorg_boxservice_offer_list">
                  {el?.servicesOffered?.map((el1, i2) => (
                    <div key={i2}>{el1}</div>
                  ))}
                </div>
              </div>
              <div className="uorg_box_next">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.10265 13.5435H20.4019L16.9727 16.9686C15.9885 17.9529 17.4649 19.4332 18.4491 18.449L21.5712 15.323L23.6578 13.2364C23.8525 13.0408 23.9618 12.7761 23.9618 12.5001C23.9618 12.2242 23.8525 11.9595 23.6578 11.7639L18.4452 6.55133C18.3469 6.45056 18.2293 6.37071 18.0994 6.31658C17.9695 6.26246 17.83 6.23517 17.6893 6.23637C17.4814 6.23691 17.2785 6.29952 17.1064 6.41618C16.9344 6.53283 16.8012 6.6982 16.7238 6.8911C16.6463 7.08399 16.6283 7.29561 16.672 7.49882C16.7156 7.70203 16.819 7.88757 16.9688 8.03164L20.4058 11.4568H2.04753C0.602653 11.5316 0.708952 13.6143 2.10265 13.5435Z"
                    fill="#D0D0D1"
                  />
                </svg>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
