import React, { useEffect, useState } from "react";
import { BottomNav } from "../../../component/Navbar/BottomNav";
import { CustomNavbar } from "../../../component/Navbar/CustomNavbar";
import { useNavigate } from "react-router-dom";
import { Inputs } from "../../../component/Input/Inputs";
import {
  AdminFaqsAdd,
  AdminFaqsList,
  admintoken,
  CheckToken,
  FaqCreate,
  FaqDelete,
  FaqEdit,
  FaqList,
  token,
} from "../../../component/Api/api";
import { useDispatch, useSelector } from "react-redux";
import { scrollToTop } from "../../../utils/utils";
import { RTE } from "../../../component/Rich text editor/RTE";

export const Faqs = ({ setloading }) => {
  const current_store = useSelector((state) => state.checktoken_data);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [FaqsList, setFaqsList] = useState([]);
  const [pagevalues, setpagevalues] = useState({
    question: "",
    answer: "",
  });

  const [Editfaqs, setEditfaqs] = useState(false);
  const [Modalfaqs, setModalfaqs] = useState(false);
  const [Search, setSearch] = useState("");
  const [msg, setmsg] = useState("");
  useEffect(() => {
    scrollToTop();
    setloading(true);
    if (admintoken || current_store?.token) {
      CheckToken(admintoken || current_store?.token, ({ status, response }) => {
        if (response.status) {
          if (response?.user?.type) {
            if (response?.user?.type == "admin") {
              dispatch({
                type: "setCheckToken",
                payload: {
                  token: admintoken || current_store?.token,
                  userdetail: response.user,
                  user_type: response?.user?.type,
                },
              });
            } else {
              nav("/organization");
            }
          } else {
            nav("/welcome");
          }
        } else {
          setloading(false);
          nav("/admin");
        }
      });
    } else {
      setloading(false);
      nav("/admin");
    }
    FaqList(admintoken, ({ status, response }) => {
      if (response.status) {
        setloading(false);
        setFaqsList(response.faqs);
      }
    });
  }, []);

  const autoExpand = (event) => {
    const textarea1 = document.getElementById("abc");
    if (textarea1) {
      textarea1.style.height = "auto";
      textarea1.style.height = 44 + "px";
    }
  };

  return (
    <>
      <CustomNavbar
        {...{
          filter_option: false,
          type: "admin",
          menu_ctr: !Modalfaqs,
          btn1: {
            txt: Modalfaqs ? (
              <svg
                width="25"
                height="26"
                viewBox="0 0 25 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.8973 13.8618H4.59813L8.02727 17.287C9.01152 18.2713 7.53514 19.7516 6.55089 18.7673L3.42884 15.6413L1.34223 13.5547C1.14749 13.3592 1.03816 13.0945 1.03816 12.8185C1.03816 12.5425 1.14749 12.2778 1.34223 12.0823L6.55483 6.86969C6.65307 6.76892 6.77068 6.68907 6.90059 6.63494C7.03049 6.58081 7.17001 6.55353 7.31073 6.55473C7.51858 6.55527 7.72153 6.61788 7.89355 6.73454C8.06557 6.85119 8.19883 7.01656 8.27625 7.20946C8.35366 7.40235 8.37169 7.61397 8.32803 7.81718C8.28437 8.02039 8.18101 8.20592 8.03121 8.35L4.5942 11.7752H22.9525C24.3973 11.85 24.291 13.9327 22.8973 13.8618Z"
                  fill="#D0D0D1"
                />
              </svg>
            ) : (
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 7.99935C4 7.64573 4.14048 7.30659 4.39052 7.05654C4.64057 6.80649 4.97971 6.66602 5.33333 6.66602H26.6667C27.0203 6.66602 27.3594 6.80649 27.6095 7.05654C27.8595 7.30659 28 7.64573 28 7.99935C28 8.35297 27.8595 8.69211 27.6095 8.94216C27.3594 9.19221 27.0203 9.33268 26.6667 9.33268H5.33333C4.97971 9.33268 4.64057 9.19221 4.39052 8.94216C4.14048 8.69211 4 8.35297 4 7.99935ZM4 23.9993C4 23.6457 4.14048 23.3066 4.39052 23.0565C4.64057 22.8065 4.97971 22.666 5.33333 22.666H12C12.3536 22.666 12.6928 22.8065 12.9428 23.0565C13.1929 23.3066 13.3333 23.6457 13.3333 23.9993C13.3333 24.353 13.1929 24.6921 12.9428 24.9422C12.6928 25.1922 12.3536 25.3327 12 25.3327H5.33333C4.97971 25.3327 4.64057 25.1922 4.39052 24.9422C4.14048 24.6921 4 24.353 4 23.9993Z"
                  fill="#D0D0D1"
                />
                <path
                  d="M4.39052 15.3905C4.14048 15.6406 4 15.9797 4 16.3333C4 16.687 4.14048 17.0261 4.39052 17.2761C4.64057 17.5262 4.97971 17.6667 5.33333 17.6667H18.6667C19.0203 17.6667 19.3594 17.5262 19.6095 17.2761C19.8595 17.0261 20 16.687 20 16.3333C20 15.9797 19.8595 15.6406 19.6095 15.3905C19.3594 15.1405 19.0203 15 18.6667 15H5.33333C4.97971 15 4.64057 15.1405 4.39052 15.3905Z"
                  fill="#D0D0D1"
                />
              </svg>
            ),
            onclick: () => {
              setModalfaqs(false);
              setEditfaqs(false);
              setpagevalues({
                question: "",
                answer: "",
                _id: "",
              });
            },
          },
          txt: "FAQ's " + (Modalfaqs ? (Editfaqs ? "edit" : "add") : ""),
          btn2: {
            txt: Modalfaqs ? (
              "Save"
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1_5802)">
                  <path
                    d="M10 20C9.741 20 9.49261 19.8971 9.30947 19.714C9.12632 19.5308 9.02344 19.2824 9.02344 19.0234V0.976562C9.02344 0.717562 9.12632 0.46917 9.30947 0.286029C9.49261 0.102888 9.741 0 10 0C10.259 0 10.5074 0.102888 10.6905 0.286029C10.8737 0.46917 10.9766 0.717562 10.9766 0.976562V19.0234C10.9766 19.2824 10.8737 19.5308 10.6905 19.714C10.5074 19.8971 10.259 20 10 20Z"
                    fill="white"
                  />
                  <path
                    d="M19.0234 10.9766H0.976562C0.717562 10.9766 0.46917 10.8737 0.286029 10.6905C0.102888 10.5074 0 10.259 0 10C0 9.741 0.102888 9.49261 0.286029 9.30947C0.46917 9.12632 0.717562 9.02344 0.976562 9.02344H19.0234C19.2824 9.02344 19.5308 9.12632 19.714 9.30947C19.8971 9.49261 20 9.741 20 10C20 10.259 19.8971 10.5074 19.714 10.6905C19.5308 10.8737 19.2824 10.9766 19.0234 10.9766Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1_5802">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            ),
            onclick: () => {
              if (Modalfaqs) {
                if (!pagevalues.question && !pagevalues.answer) {
                  return;
                }
                setloading(true);
                if (Editfaqs) {
                  FaqEdit(
                    {
                      ...pagevalues,
                    },
                    admintoken,
                    ({ status, response }) => {
                      if (response.status) {
                        setloading(false);
                        let data = FaqsList;
                        data[pagevalues.index] = response.faq;
                        setFaqsList([...data]);
                        setModalfaqs(false);
                        setpagevalues({
                          question: "",
                          answer: "",
                        });
                      } else {
                        setloading(false);
                      }
                    }
                  );
                } else {
                  FaqCreate(
                    {
                      answer: pagevalues.answer,
                      question: pagevalues.question,
                    },
                    admintoken,
                    ({ status, response }) => {
                      if (response.status) {
                        setloading(false);
                        setFaqsList((prev) => [...prev, response.faq]);
                        setpagevalues({
                          question: "",
                          answer: "",
                        });
                        autoExpand();
                        setEditfaqs(false);
                        setModalfaqs(false);
                      } else {
                        setloading(false);
                      }
                    }
                  );
                }
              } else {
                setModalfaqs(true);
              }
            },
          },
        }}
      />
      {Modalfaqs ? (
        <div className="faqs_form">
          <div>
            <Inputs
              {...{
                inpType: "type1",
                attr: {
                  type: "text",
                  id: `questa`,
                  value: pagevalues.question,
                  event: {
                    change: ({ target }) => {
                      setpagevalues((prev) => ({
                        ...prev,
                        question: target.value,
                      }));
                    },
                  },
                },
                data: {
                  placeholder: "Type your question here",
                  type: "textarea",
                  special_textarea: true,
                },
              }}
            />
          </div>
          <div>
            {/* <RTE read_only={false} msg={msg} setmsg={setmsg} /> */}
            <Inputs
              {...{
                inpType: "type1",
                attr: {
                  id: "answer",
                  class: "",
                  type: "text",
                  value: pagevalues.answer,
                  event: {
                    change: ({ target }) => {
                      setpagevalues((prev) => ({
                        ...prev,
                        answer: target.value,
                      }));
                    },
                  },
                },
                data: {
                  placeholder: "Type your description here",
                  type: "textarea",
                },
              }}
            />
          </div>
          {Editfaqs ? (
            <div
              className="faqs_delete"
              onClick={() => {
                setloading(true);
                FaqDelete(
                  { ...pagevalues },
                  admintoken || current_store?.token,
                  ({ status, response }) => {
                    if (response.status) {
                      setloading(false);
                      let data = FaqsList.filter(
                        (el) => el._id != pagevalues._id
                      );
                      setFaqsList([...data]);
                      setModalfaqs(false);
                      setEditfaqs(false);
                      setpagevalues({
                        question: "",
                        answer: "",
                      });
                    } else {
                      setloading(false);
                    }
                  }
                );
              }}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1_6446)">
                  <path
                    d="M8.28259 7.0117L13.734 1.5602C14.0887 1.20559 14.0887 0.632233 13.734 0.277671C13.3794 -0.0769322 12.806 -0.0769322 12.4515 0.277671L6.99994 5.72913L1.54861 0.277671C1.19383 -0.0769322 0.620684 -0.0769322 0.26608 0.277671C-0.0886934 0.632275 -0.0886934 1.20559 0.26608 1.5602L5.71737 7.0117L0.266123 12.4632C-0.0886509 12.8178 -0.0886509 13.3911 0.266123 13.7457C0.350236 13.83 0.450189 13.8969 0.560238 13.9425C0.670287 13.9881 0.788262 14.0115 0.907386 14.0114C1.13954 14.0114 1.37177 13.9226 1.54865 13.7457L6.99994 8.29422L12.4515 13.7457C12.5356 13.83 12.6356 13.8969 12.7456 13.9425C12.8556 13.9881 12.9736 14.0115 13.0927 14.0114C13.3249 14.0114 13.5571 13.9226 13.734 13.7457C14.0887 13.3911 14.0887 12.8178 13.734 12.4632L8.28259 7.0117Z"
                    fill="white"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_1_6446">
                    <rect width="14" height="14" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
              Delete
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="faqs_container">
          <div className="faqs_search">
            <Inputs
              {...{
                inpType: "type1",
                attr: {
                  class: "search",
                  readonly: "",
                  value: Search,
                  id: "search",
                  type: "text",
                  event: {
                    change: ({ target }) => {
                      if (
                        /^[a-zA-Z0-9\s#@]+$/.test(target.value) ||
                        target.value == ""
                      ) {
                        setSearch(target.value);
                      }
                    },
                  },
                },
                data: {
                  title: "Search",
                  prefix: (
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1_6693)">
                        <path
                          d="M17.8292 17.0207L13.1819 12.4477C14.3989 11.1255 15.1466 9.37682 15.1466 7.45261C15.146 3.33639 11.7556 0 7.57302 0C3.39042 0 0 3.33639 0 7.45261C0 11.5688 3.39042 14.9052 7.57302 14.9052C9.3802 14.9052 11.0377 14.2801 12.3397 13.241L17.005 17.832C17.2323 18.056 17.6014 18.056 17.8287 17.832C17.8827 17.7792 17.9257 17.7162 17.9551 17.6465C17.9844 17.5769 17.9996 17.5021 17.9996 17.4265C17.9997 17.3509 17.9846 17.2761 17.9554 17.2064C17.9261 17.1367 17.8832 17.0736 17.8292 17.0207ZM7.57302 13.7586C4.03408 13.7586 1.16522 10.9353 1.16522 7.45261C1.16522 3.96991 4.03408 1.14663 7.57302 1.14663C11.112 1.14663 13.9808 3.96991 13.9808 7.45261C13.9808 10.9353 11.112 13.7586 7.57302 13.7586Z"
                          fill="#D0D0D1"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1_6693">
                          <rect width="18" height="18" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  ),
                  type: "",
                },
              }}
            />
          </div>
          {FaqsList?.filter((el) =>
            Object.values(el)
              .join(",")
              .toLowerCase()
              .match(Search.toLowerCase())
          ).length == 0 ? (
            <div className="faqs_nodata">
              <div className="p_svg">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1_5535)">
                    <path
                      d="M12.5 19.7031C13.1742 19.7031 13.7207 19.1566 13.7207 18.4824C13.7207 17.8082 13.1742 17.2617 12.5 17.2617C11.8258 17.2617 11.2793 17.8082 11.2793 18.4824C11.2793 19.1566 11.8258 19.7031 12.5 19.7031Z"
                      fill="#B8B8B9"
                    ></path>
                    <path
                      d="M12.5 0C5.5916 0 0 5.59067 0 12.5C0 19.4084 5.59067 25 12.5 25C19.4084 25 25 19.4093 25 12.5C25 5.5916 19.4093 0 12.5 0ZM12.5 23.0469C6.67104 23.0469 1.95312 18.3297 1.95312 12.5C1.95312 6.67104 6.67026 1.95312 12.5 1.95312C18.329 1.95312 23.0469 6.67026 23.0469 12.5C23.0469 18.329 18.3297 23.0469 12.5 23.0469Z"
                      fill="#B8B8B9"
                    ></path>
                    <path
                      d="M12.5 6.27344C10.3461 6.27344 8.59375 8.02578 8.59375 10.1797C8.59375 10.719 9.03096 11.1562 9.57031 11.1562C10.1097 11.1562 10.5469 10.719 10.5469 10.1797C10.5469 9.10273 11.423 8.22656 12.5 8.22656C13.577 8.22656 14.4531 9.10273 14.4531 10.1797C14.4531 11.2566 13.577 12.1328 12.5 12.1328C11.9606 12.1328 11.5234 12.57 11.5234 13.1094V15.5508C11.5234 16.0901 11.9606 16.5273 12.5 16.5273C13.0394 16.5273 13.4766 16.0901 13.4766 15.5508V13.9624C15.1595 13.5276 16.4062 11.9965 16.4062 10.1797C16.4062 8.02578 14.6539 6.27344 12.5 6.27344Z"
                      fill="#B8B8B9"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_1_5535">
                      <rect width="25" height="25" fill="white"></rect>
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="p_text">No Faqs</div>
            </div>
          ) : (
            <>
              <div className="faqs_title">
                Frequently Asked Questions (FAQs) for the ACC=SS App:
              </div>
              <div className="faqs_list">
                {FaqsList?.filter((el) =>
                  Object.values(el)
                    .join(",")
                    .toLowerCase()
                    .match(Search.toLowerCase())
                )?.map((el, i) => (
                  <div
                    key={i}
                    className="faqs_box"
                    onClick={(e) => {
                      setpagevalues({ ...el, index: i });
                      setModalfaqs(true);
                      setEditfaqs(true);
                    }}
                  >
                    <div>
                      <div className="faqs_box_q">{el.question}</div>
                      <div className="faqs_box_a">{el.answer}</div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="faqs_bottom_line">
                Remember, ACC=SS is committed to breaking barriers and providing
                meaningful support to individuals with felony convictions,
                financially challenged, and veterans on their journey toward a
                brighter future.
              </div>
            </>
          )}
        </div>
      )}
      <BottomNav type={"admin"} />
    </>
  );
};
