import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Inputs } from "../../../component/Input/Inputs";
import {
  CheckToken,
  SendOtp,
  token,
  VerifyOtp,
} from "../../../component/Api/api";
import OR_LoginThrough from "../../../component/OR_LoginThrough.js/OR_LoginThrough";
import { useDispatch, useSelector } from "react-redux";
import { checkPlan } from "../../../utils/utils";

export const Login = ({ setloading }) => {
  const current_store = useSelector((state) => state.checktoken_data);
  let token = localStorage.getItem("user_token");
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [pagevalues, setpagevalues] = useState({
    email: "",
    password: "",
  });
  const [errpagevalues, seterrpagevalues] = useState({
    email: false,
    password: false,
  });
  const [otpValues, setOtpValues] = useState(["", "", "", ""]);
  const [error_otp, seterror_otp] = useState("");
  const [SendPin, setSendPin] = useState(false);
  useEffect(() => {
    if (current_store?.token || token) {
      setloading(true);
      CheckToken(current_store?.token || token, ({ status, response }) => {
        if (response.status) {
          if (response?.user?.type == "admin") {
            dispatch({
              type: "setCheckToken",
              payload: {
                token: current_store?.token || token,
                userdetail: response.user,
                user_type: response?.user?.type,
              },
            });
            nav("/admin/dashboard");
            setloading(false);
          } else {
            if (response?.user?.type) {
              dispatch({
                type: "setCheckToken",
                payload: {
                  token: current_store?.token || token,
                  userdetail: response.user,
                  user_type: response?.user?.type,
                },
              });

              nav("/organization");
              setloading(false);
            } else {
              nav("/welcome");
              setloading(false);
            }
          }
        } else {
          setloading(false);
        }
      });
    }
  }, [current_store?.token]);
  const handleInputChange = (index, value) => {
    const updatedValues = [...otpValues];
    updatedValues[index] = value;
    setOtpValues(updatedValues);
    seterror_otp("");
    if (value && index < otpValues.length - 1) {
      const nextInput = document.getElementsByName(`otp-${index + 1}`)[0];
      nextInput.focus();
    }
    if (updatedValues.join("").length == 4) {
      handle_login(updatedValues);
    }
  };
  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !otpValues[index] && index > 0) {
      const previousInput = document.getElementsByName(`otp-${index - 1}`)[0];
      previousInput.focus();
    }
  };
  const handle_sendotp = () => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i.test(pagevalues.email)) {
      seterrpagevalues((prev) => ({
        ...prev,
        email: !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i.test(
          pagevalues.email
        ),
      }));

      return;
    }
    setSendPin(true);
    SendOtp(pagevalues, ({ status, response }) => {});
  };
  const handle_login = (otpValues) => {
    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i.test(pagevalues.email) ||
      otpValues?.join("")?.length != 4
    ) {
      seterrpagevalues((prev) => ({
        ...prev,
        email: !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i.test(
          pagevalues.email
        ),
      }));
      seterror_otp(true);
      return;
    }
    setloading(true);
    VerifyOtp(
      { ...pagevalues, password: otpValues?.join("") },
      ({ status, response }) => {
        if (response.token) {
          if (response?.user?.type == "admin") {
            localStorage.setItem("user_token", response.token);
            dispatch({
              type: "setCheckToken",
              payload: {
                userdetail: response.user,
                token: response.token,
                user_type: response?.user?.type,
              },
            });
            nav("/admin/dashboard");
          } else {
            localStorage.setItem("user_token", response.token);
            setSendPin(true);
            if (response?.user?.name) {
              if (
                (response?.user?.directlyImpactedOrg ||
                  response?.user?.governmentOrg) &&
                !response?.user?.dioApproval
              ) {
                nav("/requestprocessing");
              } else {
                localStorage.setItem("userType", response.user.type);
                dispatch({
                  type: "setCheckToken",
                  payload: {  
                    token: response.token,
                    userdetail: response.user,
                    user_type: response?.user?.type,
                  },
                });
                nav("/organization");
              }
            } else {
              dispatch({
                type: "setCheckToken",
                payload: {
                  token: response.token,
                  userdetail: response.user,
                },
              });
              nav("/welcome");
            }
          }
        } else {
          seterror_otp(true);
          setloading(false);
        }
      }
    );
  };
  return (
    <div className="login_page_user">
      <div className="login_page_circle"></div>
      <img src="https://access-files.sfo2.digitaloceanspaces.com/images/17235314270121723531427009.png" />
      {SendPin ? (
        <>
          <div className="login_edit">
            <div className="login_desc">
              An email had been sent to {pagevalues?.email}
            </div>
            <div
              className="login_edit_btn"
              onClick={() => {
                setSendPin(false);
                setOtpValues(["", "", "", ""]);
                seterror_otp(false);
              }}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_251_1117)">
                  <path
                    d="M12.351 3.35917L1.3468 14.3642C1.29139 14.4198 1.2519 14.4892 1.23244 14.5652L0.0126888 19.4609C-0.00525068 19.5336 -0.00415128 19.6096 0.0158808 19.6817C0.0359129 19.7538 0.0742006 19.8196 0.127046 19.8725C0.208215 19.9534 0.318116 19.9989 0.432715 19.999C0.468068 19.999 0.503284 19.9946 0.537569 19.986L5.43327 18.7661C5.5094 18.747 5.57888 18.7075 5.63429 18.6519L16.6395 7.6476L12.351 3.35917ZM19.3657 1.85864L18.1408 0.633734C17.3221 -0.184958 15.8952 -0.184146 15.0774 0.633734L13.5769 2.13426L17.8652 6.42253L19.3657 4.92204C19.7747 4.51326 19.9999 3.96913 19.9999 3.39044C19.9999 2.81175 19.7747 2.26763 19.3657 1.85864Z"
                    fill="white"
                    fill-opacity="0.8"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_251_1117">
                    <rect width="20" height="20" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div className="opt_stand">
            {otpValues?.map((value, index) => (
              <input
                key={index}
                type="number"
                maxLength="1"
                className={
                  "opt_design " +
                  (value != "" ? "opt_design_active " : "") +
                  (error_otp ? " error_o" : "")
                }
                inputMode="numeric"
                name={`otp-${index}`}
                placeholder=""
                value={value}
                onChange={(e) => handleInputChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
              />
            ))}
          </div>
        </>
      ) : (
        <Inputs
          {...{
            inpType: "type1",
            attr: {
              type: "text",
              class: "login_email",
              value: pagevalues.email,
              error: errpagevalues?.email,
              event: {
                change: ({ target }) => {
                  setpagevalues((prev) => ({
                    ...prev,
                    email: target.value.toLowerCase(),
                  }));
                  setSendPin(false);
                  seterrpagevalues((prev) => ({
                    ...prev,
                    email:
                      !target.value ||
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i.test(
                        target.value
                      ),
                  }));
                },
              },
            },
            data: {
              title: "Email address",
              prefix: "",
              type: "",
            },
          }}
        />
      )}

      {SendPin ? (
        <>
          <div
            className="login_resend_pin"
            onClick={() => {
              handle_sendotp();
            }}
          >
            Resend Pin
          </div>
        </>
      ) : (
        <div
          className="login_btn"
          onClick={() => {
            if (SendPin) {
              handle_login();
            } else {
              handle_sendotp();
            }
          }}
        >
          {SendPin ? "Verify otp" : "Send otp"}
        </div>
      )}
    </div>
  );
};
