import React from "react";
import { Chats } from "../../../component/Message/Chats";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const UOrganizationIndiMessage = ({
  Page,
  setPage,
  Lists,
  setLists,
  View,
  setView,
}) => {
  const nav = useNavigate();
  const [user_data, setuser_data] = useState({
    chat_id: "",
    user_id: "user1",
    user_name: "",
    user_img: "",
    chat_name: "",
  });
  const [chat, setchat] = useState([
    {
      msg_id: "1224",
      sent_by: "user1",
      msg: "Amc",
      time_stamp: new Date(),
      reaction: "star",
    },
    {
      msg_id: "12204",
      sent_by: "user1",
      msg: "Amc\nasdsad\nasdasdasdasdsd",
      time_stamp: new Date(),
      reaction: "",
    },
    {
      msg_id: "12242",
      sent_by: "replier",
      msg: "Amc\nasdsad\nasdasdasdasdsadasdasd",
      time_stamp: new Date(),
      reaction: "star",
    },
    {
      msg_id: "44224",
      sent_by: "replier",
      msg: "Dhiraj i am great to work with you",
      time_stamp: new Date(),
      reaction: "",
    },
    {
      msg_id: "44224",
      sent_by: "user1",
      msg: "Thank You",
      time_stamp: new Date(),
      reaction: "star",
    },
  ]);
  const [all_data, setAll_data] = useState({});
  return (
    <>
      <Chats
        {...{
          setchat,
          chat: chat,
          user_data,
          all_data,
          onbackclick: () => {
            setPage("orgindview");
          },
        }}
      />
    </>
  );
};
