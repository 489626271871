import React from "react";

export const UOrgViewsInti = ({
  Page,
  setPage,
  Lists,
  setLists,
  View,
  setView,
}) => {
  return (
    <div className="uorg_views_section">
      <img src="https://imgur.com/O8TrUKL.jpeg" />
      <div className="uorg_views_title">{View.name}</div>
      <div className="uorg_views_type">Organization</div>
      <div className="uorg_views_title1">About us</div>
      <div className="uorg_views_desc">
        Sorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate
        libero et velit interdum, ac aliquet odio mattis.
      </div>
      <div className="uorg_views_title1">Website</div>
      <div className="uorg_views_desc">Mondaysolutions.com</div>
      <div className="uorg_views_title1">
        Number of employess hired via ACC=SS in Monday Solutions?{" "}
      </div>
      <div className="uorg_views_desc">18</div>
      <div className="uorg_views_title1">Services offered</div>
      <div className="uorg_views_list">
        {View?.serviceoffer?.map((el, i) => (
          <div key={i}>{el}</div>
        ))}
      </div>
      <div className="uorg_views_btns">Start Chatting</div>
    </div>
  );
};
