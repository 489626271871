import React, { useState } from "react";
import { Inputs } from "../../../component/Input/Inputs";
import { SvgCollection } from "../../../component/Image/Image";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { CheckBox } from "../../../component/CheckBox/CheckBox";
import { ThemeProvider, createTheme } from "@mui/material/styles";

export const AddCards = ({ cardvalues, setcardvalues, Page, setPage }) => {
  const [Primary, setPrimary] = useState(false);
  const [errpagevalues, seterrpagevalues] = useState({
    cardholder_name: false,
    cardholder_number: false,
    security_code: false,
  });
  const identifyCardBrand = (inputValue) => {
    const trimmedValue = inputValue.replace(/\s/g, "");

    if (/^4/.test(trimmedValue)) {
      return "visa";
    } else if (/^(5[1-5]|2[2-7])/.test(trimmedValue)) {
      return "mastercard";
    } else if (/^3[47]/.test(trimmedValue)) {
      return "americanexp";
    } else if (/^6(?:011|5)/.test(trimmedValue)) {
      return "discover";
    } else if (/^3(?:0[0-5]|[68])/.test(trimmedValue)) {
      return "dinersclub";
    } else if (/^(?:2131|1800|35)/.test(trimmedValue)) {
      return "jcb";
    } else if (/^62/.test(trimmedValue)) {
      return "unionpay";
    } else {
      return "Unknown";
    }
  };
  return (
    <div className="addcard_container">
      <div className="addcard_title">Please provide the details:</div>
      <div className="add_card_in">
        <Inputs
          {...{
            inpType: "type1",
            attr: {
              class: "",
              readonly: "",
              value: cardvalues.cardholder_name,
              error: errpagevalues?.cardholder_name,
              type: "text",
              event: {
                change: ({ target }) => {
                  setcardvalues((prev) => ({
                    ...prev,
                    cardholder_name: target.value,
                  }));
                  seterrpagevalues((prev) => ({
                    ...prev,
                    cardholder_name: !target.value,
                  }));
                },
              },
            },
            data: {
              title: "Cardholder Name",
              prefix: "",
              type: "",
            },
          }}
        />
      </div>

      <div className="add_card_in">
        <Inputs
          {...{
            inpType: "type1",
            attr: {
              class: "",
              readonly: "",
              value: cardvalues.cardholder_number,
           
              error: errpagevalues?.cardholder_number,
              type: "text",
              event: {
                change: ({ target }) => {
                  const numericValue = target.value.replace(/\D/g, "");
                  let formattedValue = "";

                  for (let i = 0; i < numericValue.length; i++) {
                    if (i > 0 && i % 4 === 0) {
                      formattedValue += " ";
                    }
                    formattedValue += numericValue[i];
                  }

                  if (formattedValue.replaceAll(" ", "").length > 16) {
                    return;
                  }
                  setcardvalues((prev) => ({
                    ...prev,
                    cardholder_number: formattedValue,
                    card_brand: identifyCardBrand(formattedValue),
                  }));
                  seterrpagevalues((prev) => ({
                    ...prev,
                    cardholder_number: !formattedValue,
                  }));
                },
              },
            },
            data: {
              title: "Card Number",
              prefix: "",
              type: "",
              suffix: <SvgCollection img_var={cardvalues.card_brand} />,
            },
          }}
        />
      </div>

      <div className="addcard_inputs">
        <ThemeProvider
          theme={createTheme({
            palette: {
              mode: "dark",
            },
          })}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              variant="standard"
              sx={{
                width: "100%",
                color: "white !important",
                "& .Mui-error .MuiOutlinedInput-notchedOutline": {
                  borderColor: "var(--red) !important",
                  outline: "0px !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(255, 255, 255, 0.20) !important",
                  borderWidth: "1px !important",
                  color: "white !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(255, 255, 255, 0.20) !important",
                  borderRadius: "8px !important",
                  borderWidth: "1px !important",
                  color: "white !important",
                },
              }}
              disablePast
              label="Expiry Date"
              className={"cust_input"}
              value={
                cardvalues.expiry_date
                  ? dayjs(new Date(cardvalues.expiry_date))
                  : null
              }
              onChange={(value) => {
                setcardvalues((prev) => ({
                  ...prev,
                  expiry_date: value,
                }));
                seterrpagevalues((prev) => ({
                  ...prev,
                  expiry_date: !value,
                }));
              }}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </ThemeProvider>

        <Inputs
          {...{
            inpType: "type1",
            attr: {
              class: "",
              readonly: "",
              value: cardvalues.security_code,
              error: errpagevalues?.security_code,
              type: "text",
              event: {
                change: ({ target }) => {
                  const numericValue = target.value.replace(/\D/g, "");
                  setcardvalues((prev) => ({
                    ...prev,
                    security_code: numericValue,
                  }));
                  seterrpagevalues((prev) => ({
                    ...prev,
                    security_code: !numericValue,
                  }));
                },
              },
            },
            data: {
              title: "Security Code",
              prefix: "",
              type: "",
            },
          }}
        />
      </div>
      <div
        className="addcard_primary"
        onClick={() => {
          setPrimary((prev) => !prev);
        }}
      >
        <CheckBox
          {...{
            check: Primary,
            onclick: () => {},
          }}
        />
        <div>Add as primary card</div>
      </div>
      <div
        className="addcard_btns"
        onClick={() => {
          if (
            !cardvalues.security_code ||
            !cardvalues.cardholder_number ||
            !cardvalues.cardholder_name ||
            !cardvalues.expiry_date
          ) {
            seterrpagevalues((prev) => ({
              ...prev,
              cardholder_name: !cardvalues.cardholder_name,
              cardholder_number: !cardvalues.cardholder_number,
              security_code: !cardvalues.security_code,
              expiry_date: !cardvalues.expiry_date,
            }));
            return;
          }

          setPage("organization");
        }}
      >
        Save
      </div>
    </div>
  );
};
