import React from "react";
import { OrganizationList } from "./OrganizationList";
import { OrganizationView } from "./OrganizationView";
import { OrganizationEdit } from "./OrganizationEdit";

export const OrganizationAll = ({ pages, setPages, otherdata }) => {
  const data = {
    list: (
      <OrganizationList
        {...{
          setPages,
          ...otherdata,
        }}
      />
    ),
    orgview: (
      <OrganizationView
        {...{
          setPages,
          ...otherdata,
        }}
      />
    ),
    orgedit: (
      <OrganizationEdit
        {...{
          setPages,
          ...otherdata,
        }}
      />
    ),
  };
  return data[pages];
};
