import React, { useEffect, useState } from "react";
import { Chats } from "../../../component/Message/Chats";
import { useNavigate } from "react-router-dom";
import {
  AdminCreateChat,
  CheckToken,
  CreateChat,
  token,
} from "../../../component/Api/api";
import { useDispatch, useSelector } from "react-redux";
import { AdminChats } from "../../../component/Message/AdminChats";
import { checkPlan } from "../../../utils/utils";

export const Support = () => {
  const current_store = useSelector((state) => state.checktoken_data);
  const token = localStorage.getItem("user_token");
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [ChatData, setChatData] = useState({});
  const [OpenChat, setOpenChat] = useState(false);
  useEffect(() => {
    if (current_store?.token || token) {
      CheckToken(current_store?.token || token, ({ status, response }) => {
        if (response.status) {
          if (response?.user?.type) {
            if (
              response?.user?.type == "organization" ||
              response?.user?.type == "individual"
            ) {
              dispatch({
                type: "setCheckToken",
                payload: {
                  token: current_store?.token || token,
                  userdetail: response.user,
                  user_type: response?.user?.type,
                },
              });
              checkPlan({
                ...response.user,
                ongoto: () => {
                  nav("/checkout");
                },
                ongopending: () => {
                  nav("/requestprocessing");
                },
              });
            } else {
              nav("/admin/dashboard");
            }
          } else {
            nav("/welcome");
          }
        } else {
          nav("/login");
        }
      });
    } else {
      nav("/login");
    }
  }, []);
  useEffect(() => {
    AdminCreateChat(
      {
        sender: current_store.userdetail.id,
      },
      current_store?.token || token,
      ({ status, response }) => {
        if (response.status) {
          setChatData(response?.chat?.chat);
          setOpenChat(true);
        }
      }
    );
  }, [current_store]);

  return OpenChat ? (
    <>
      <AdminChats
        {...{
          user_data: current_store.userdetail,
          ChatData,
          OpenChats: OpenChat,
          onbackclick: () => {
            nav("/organization");
          },
        }}
      />
    </>
  ) : (
    ""
  );
};
