import React from "react";

export const Loader = ({ loading }) => {
  return (
    <>
      <div className="loader">
        <div className="anim_loader">
          <svg
            width="105"
            height="105"
            viewBox="0 0 105 105"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M49.2188 8.75C49.2188 7.87976 49.5645 7.04516 50.1798 6.42981C50.7952 5.81445 51.6298 5.46875 52.5 5.46875C78.4744 5.46875 99.5312 26.5256 99.5312 52.5C99.5312 78.4744 78.4744 99.5312 52.5 99.5312C26.5256 99.5312 5.46875 78.4744 5.46875 52.5C5.46875 51.6298 5.81445 50.7952 6.42981 50.1798C7.04516 49.5645 7.87976 49.2188 8.75 49.2188C9.62024 49.2188 10.4548 49.5645 11.0702 50.1798C11.6855 50.7952 12.0312 51.6298 12.0312 52.5C12.0312 60.504 14.4047 68.3282 18.8515 74.9832C23.2982 81.6383 29.6186 86.8253 37.0133 89.8882C44.408 92.9512 52.5449 93.7526 60.3951 92.1912C68.2452 90.6297 75.4561 86.7754 81.1157 81.1157C86.7754 75.4561 90.6297 68.2452 92.1912 60.3951C93.7526 52.5449 92.9512 44.408 89.8882 37.0133C86.8253 29.6186 81.6383 23.2982 74.9832 18.8515C68.3282 14.4047 60.504 12.0312 52.5 12.0312C51.6298 12.0312 50.7952 11.6855 50.1798 11.0702C49.5645 10.4548 49.2188 9.62024 49.2188 8.75Z"
              fill="url(#paint0_linear_1_518)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1_518"
                x1="52.5"
                y1="5.46875"
                x2="52.5"
                y2="99.5312"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#313D92" />
                <stop offset="1" stopColor="#D83A35" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        {/* <div className="anim_loader_title">Please wait....</div> */}
      </div>
    </>
  );
};
