import React from "react";

export const ItemCard = ({ name, desc, type, image, onclick, active }) => {
  return (
    <div
      className="item_card_container"
      onClick={() => {
        onclick();
      }}
    >
      <div
        className="item_card_image"
        style={{
          backgroundImage: `url(${image || "https://imgur.com/Yjchy3r.png"})`,
        }}
      ></div>
      <div className="item_card_detail">
        <div className="item_card_top">
          <div className="item_card_name">{name}</div>
          {type == "organization" ? (
            <div
              className={
                "item_card_active" + (active ? " active" : " inactive")
              }
            ></div>
          ) : (
            ""
          )}
        </div>
        <div className="item_card_desc">{desc}</div>
      </div>
    </div>
  );
};
