import React, { useState } from "react";
import { json, useNavigate } from "react-router-dom";
import { ChatNewMessage, CreateChat, token } from "../../../component/Api/api";

export const SelectOrgView = ({
  Page,
  setPage,
  pagevalues,
  setpagevalues,
  EditProfile,
  setEditProfile,
  List,
  setList,
  cardvalues,
  setcardvalues,
  ListsOrg,
  setListsOrg,
  ViewSelectOrg,
  setViewSelectOrg,
}) => {
  const nav = useNavigate();
  const [SendProfile, setSendProfile] = useState(false);

  const send_profile = () => {
    CreateChat(
      {
        sender: pagevalues.id,
        receiver: ViewSelectOrg.id,
      },
      token,
      ({ status, response }) => {
        if (response.status) {
          ChatNewMessage(
            {
              chatId: response?.chat?.chat?._id,
              sender: pagevalues.id,
              message: JSON.stringify(pagevalues),
              messageType: "profile",
              repliedTo: "",
            },
            token,
            ({ status, response }) => {
              if (response.status) {
                setSendProfile(true);
              }
            }
          );
        }
      }
    );
  };

  return (
    <div className="select_org_view_section">
      <img src={ViewSelectOrg?.picture} />
      <div className="select_org_view_title">{ViewSelectOrg.name}</div>
      <div className="select_org_view_type">{ViewSelectOrg.type}</div>
      <div className="select_org_view_title1">About us</div>
      <div className="select_org_view_desc">{ViewSelectOrg.description}</div>
      <div className="select_org_view_title1">Phone</div>
      <div className="select_org_view_desc">{ViewSelectOrg.phone}</div>
      <div className="select_org_view_title1">Email</div>
      <div className="select_org_view_desc">{ViewSelectOrg.email}</div>
      <div className="select_org_view_title1">Contact Person Name</div>
      <div className="select_org_view_desc">
        {ViewSelectOrg.contactPersonName}
      </div>
      <div className="select_org_view_title1">Address</div>
      <div className="select_org_view_desc">{ViewSelectOrg.address}</div>
      <div className="select_org_view_title1">
        Is this a directly impacted organization?
      </div>
      <div className="select_org_view_desc">
        {ViewSelectOrg.directlyImpactedOrg ? "Yes" : "No"}
      </div>
      <div className="select_org_view_title1">
        Do you exclude individuals with a history of violence?
      </div>
      <div className="select_org_view_desc">
        {ViewSelectOrg.excludeViolenceHistory ? "Yes" : "No"}
      </div>
      <div className="select_org_view_title1">
        Do you exclude people with sexual offenses?
      </div>
      <div className="select_org_view_desc">
        {ViewSelectOrg.excludeSexualOffenses ? "Yes" : "No"}
      </div>
      <div className="select_org_view_title1">Services offered</div>
      <div className="select_org_view_list">
        {ViewSelectOrg?.servicesOffered?.map((el, i) => (
          <div key={i}>{el}</div>
        ))}
      </div>
      <div
        className="select_org_view_btns"
        onClick={() => {
          send_profile();
        }}
      >
        Send Profile
      </div>
      {SendProfile ? (
        <div className="select_org_view_goto">
          <div>The organization received your profile.</div>
          <div>
            Check it in your{" "}
            <span
              onClick={() => {
                nav("/message");
              }}
            >
              chat box.
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
