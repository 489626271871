import React, { useEffect, useState } from "react";

export const CustSwitch = ({ custclass, onevent, current, sides }) => {
  const [controller, setController] = useState(current);
  useEffect(() => {
    setController(current);
  }, [current]);

  function btnSwitch() {
    setController(controller == "left" ? "right" : "left");
    onevent(controller == "left" ? "right" : "left");
  }
  return (
    <>
      <div className={"switch_main " + (custclass ? ` ${custclass}` : "")}>
        <div
          onClick={() => {
            setController("left");
            onevent("left");
          }}
          className={`switch_title ${controller == "left" ? "active" : ""}`}
        >
          {sides?.left}
        </div>
        <div
          className={`switch-btn ${
            controller == "left" ? "leftBorder" : "rightBorder"
          }`}
          onClick={() => btnSwitch()}
        >
          <div
            className={
              controller == "left"
                ? `switch-btn-circle left `
                : `switch-btn-circle right`
            }
          >
            <span> </span>
          </div>
        </div>
        <div
          className={`switch_title ${controller == "right" ? "active" : ""}`}
          onClick={() => {
            setController("right");
            onevent("right");
          }}
        >
          {sides?.right}
        </div>
      </div>
    </>
  );
};
