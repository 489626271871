import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  createEditor,
  Editor,
  Text,
  Transforms,
  Element as SlateElement,
} from "slate";
import { withHistory } from "slate-history";

import { Slate, Editable, withReact, useSlate } from "slate-react";

import { RTE_Icons } from "./RTE_Icons";

const initialValue = [
  {
    type: "paragraph",
    align: "left",
    children: [
      {
        text: "",
      },
    ],
  },
];
const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];
export let get_editor_child;
export const RTE = ({
  read_only,
  msg,
  setmsg,
  others_msg_parts,
  setothers_msg_parts,
  read_child,
}) => {
  const image_reference_scroll = useRef();

  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  get_editor_child = () => {
    if (!read_only) {
      return editor.children;
    }
  };

  const [toolbar_active, settoolbar_active] = useState(false);
  const renderElement = useCallback(
    (props) => <RenderElement {...props} />,
    []
  );

  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const CustomEditor = {
    checkActive: {
      isBoldMarkActive(editor) {
        const [match] = Editor.nodes(editor, {
          match: (n) => n.bold === true,
          universal: true,
        });
        return !!match;
      },

      isCodeBlockActive(editor) {
        const [match] = Editor.nodes(editor, {
          match: (n) => n.type === "code",
        });
        return !!match;
      },

      isCodeBlockTestRed(editor) {
        const [match] = Editor.nodes(editor, {
          match: (n) => n.type === "test-red",
        });
        return !!match;
      },
      isCodeBlockTestBlue(editor) {
        const [match] = Editor.nodes(editor, {
          match: (n) => n.type === "test-blue",
        });
        return !!match;
      },
    },

    toggleTestRed(editor) {
      const isActive = CustomEditor.checkActive.isCodeBlockTestRed(editor);
      Transforms.setNodes(
        editor,
        { type: isActive ? null : "test-red" },
        { match: (n) => Editor.isBlock(editor, n) }
      );
    },

    isCodeBlockTestBlue(editor) {
      const [match] = Editor.nodes(editor, {
        match: (n) => n.type === "test-blue",
      });

      return !!match;
    },

    toggleTestBlue(editor) {
      const isActive = CustomEditor.checkActive.isCodeBlockTestBlue(editor);
      Transforms.setNodes(
        editor,
        { type: isActive ? null : "test-blue" },
        { match: (n) => Editor.isBlock(editor, n) }
      );
    },
    toggleBold(editor) {
      const isActive = CustomEditor.checkActive.isBoldMarkActive(editor);
      Transforms.setNodes(
        editor,
        { bold: isActive ? null : true },
        { match: (n) => Text.isText(n), split: true }
      );
    },

    toggleCodeBlock(editor) {
      const isActive = CustomEditor.checkActive.isCodeBlockActive(editor);
      Transforms.setNodes(
        editor,
        { type: isActive ? null : "code" },
        { match: (n) => Editor.isBlock(editor, n) }
      );
    },
  };
  useEffect(() => {
    if (setmsg) {
      setmsg([...editor.children]);
    }
  }, [editor]);
  let key_hold = [];
  const remove_key_hold = (data) =>
    (key_hold = key_hold.filter((el) => el !== data));
  const check_window = (e) => {
    let current_tag = e.target;
    while (current_tag) {
      if (current_tag.id == "rte_main") {
        return;
      }
      if (!current_tag) {
        break;
      }
      current_tag = current_tag.parentNode;
    }
    settoolbar_active(false);
    remove_window_click();
  };
  const add_window_click = () => {
    setTimeout(() => {
      if (!toolbar_active) {
        window.addEventListener("click", check_window);
      }
    }, 100);
  };
  const remove_window_click = () => {
    window.removeEventListener("click", check_window);
  };
  return (
    <div
      className="rte"
      ref={image_reference_scroll}
      onMouseEnter={() => {}}
      onClick={() => {
        settoolbar_active(true);
        add_window_click();
      }}
      id="rte_main"
    >
      <Slate
        editor={editor}
        initialValue={
          read_only && msg != ""
            ? msg
            : msg != ""
            ? [...msg]
            : [...initialValue]
        }
      >
        {!false && (
          <Toolbar
            active={toolbar_active}
            className="toolbar"
            tools={[
              <MarkButton format="bold" icon="bold" />,
              <MarkButton format="italic" icon="italic" />,
              <MarkButton format="underline" icon="underline" />,
              <MarkButton format="code" icon="code" />,
              <BlockButton format="heading-1" icon="h1" />,
              <BlockButton format="heading-2" icon="h2" />,
              <BlockButton format="heading-3" icon="h3" />,
              <BlockButton format="heading-4" icon="h4" />,
              <BlockButton format="heading-5" icon="h5" />,
              <BlockButton format="heading-6" icon="h6" />,
            ]}
          />
        )}

        {read_only && msg != "" ? (
          <Editable
            readOnly
            renderElement={renderElement}
            renderLeaf={renderLeaf}
          />
        ) : (
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder="Type a message…"
            onKeyUp={(e) => {
              if (e.ctrlKey) {
                e.preventDefault();
                ["b", "i", "u", "s", "c"].includes(e.key) && e.preventDefault();
                switch (e.key) {
                  case "b":
                    toggleMark(editor, "bold");
                    break;

                  case "i":
                    toggleMark(editor, "italic");
                    break;

                  case "u":
                    toggleMark(editor, "underline");
                    break;

                  case "c":
                    toggleMark(editor, "code");
                    break;

                  case "1":
                    if (key_hold.indexOf("h") !== -1) {
                      toggleBlock(editor, "heading-1");
                    }
                    break;
                  case "2":
                    if (key_hold.indexOf("h") !== -1) {
                      toggleBlock(editor, "heading-1");
                    }
                    break;
                  case "3":
                    if (key_hold.indexOf("h") !== -1) {
                      toggleBlock(editor, "heading-1");
                    }
                    break;
                  case "4":
                    if (key_hold.indexOf("h") !== -1) {
                      toggleBlock(editor, "heading-1");
                    }
                    break;
                  case "5":
                    if (key_hold.indexOf("h") !== -1) {
                      toggleBlock(editor, "heading-1");
                    }
                    break;
                  case "6":
                    if (key_hold.indexOf("h") !== -1) {
                      toggleBlock(editor, "heading-1");
                    }
                    break;

                  default:
                    break;
                }
              }
            }}
          />
        )}
      </Slate>
    </div>
  );
};

const DefaultElement = (props) => {
  return <div {...props.attributes}>{props.children}</div>;
};
const CodeElement = (props) => {
  return (
    <div {...props.attributes}>
      <code>{props.children}</code>
    </div>
  );
};
const TestElementRed = (props) => {
  return (
    <div style={{ background: "red" }} {...props.attributes}>
      <div>{props.children}</div>
    </div>
  );
};
const TestElementBlue = (props) => {
  return (
    <div style={{ background: "blue" }} {...props.attributes}>
      <div>{props.children}</div>
    </div>
  );
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};
const Toolbar = ({ className, tools, active }) => {
  return (
    <div class={"toolbar" + (active ? " active" : "")}>
      {tools.map((el, i) => {
        if (el.title) {
          return <ToolbarDropdown data={el} key={i} />;
        }
        return el;
      })}
    </div>
  );
};
const ToolbarDropdown = ({ data }) => {
  const [height, setheight] = useState({
    min_height: 0,
    total_height: 0,
  });
  const title_height = useRef();
  const body_height = useRef();
  useEffect(() => {
    setheight({
      min_height: 10,
      total_height: 0,
    });
  }, []);
  return (
    <div className="tool_dropdown">
      <div className="tool_dropdown_title" ref={title_height}>
        {data.title}
      </div>
      <div className="tool_dropdown_main">{data.body.map((el1) => el1)}</div>
    </div>
  );
};
const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <div
      className={"tool" + (isMarkActive(editor, format) ? " active" : "")}
      onClick={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <RTE_Icons icon={icon} active={false} />
    </div>
  );
};
const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};
const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <div
      className={
        "tool" +
        (isBlockActive(
          editor,
          format,
          TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
        )
          ? " active"
          : "")
      }
      onClick={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <RTE_Icons icon={icon} />
    </div>
  );
};
const isBlockActive = (editor, format, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );

  return !!match;
};
const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties = {};
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};
export const RenderElement = ({ attributes, children, element }) => {
  const style = { textAlign: element.align };

  switch (element.type) {
    case "block-quote":
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case "heading-1":
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case "heading-2":
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case "heading-3":
      return (
        <h3 style={style} {...attributes}>
          {children}
        </h3>
      );
    case "heading-4":
      return (
        <h4 style={style} {...attributes}>
          {children}
        </h4>
      );
    case "heading-5":
      return (
        <h5 style={style} {...attributes}>
          {children}
        </h5>
      );
    case "heading-6":
      return (
        <h6 style={style} {...attributes}>
          {children}
        </h6>
      );
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <div className="default_txt_box" style={style} {...attributes}>
          {children}
        </div>
      );
  }
};
