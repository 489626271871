import React, { useEffect, useState } from "react";
import { date_formatter } from "../../utils/utils";
import { CheckBox } from "../CheckBox/CheckBox";

export const ViewCardIndividual = ({ ViewData }) => {
  const [CheckBoxList, setCheckBoxList] = useState({
    active: false,
    inactive: false,
    delete: false,
  });
  return (
    <div className="view_container">
      <img
        src={
          ViewData?.picture ||
          "https://access-files.sfo2.digitaloceanspaces.com/images/17216567670051721656767001.webp"
        }
      />
      <div className="view_detail_title">{ViewData.name}</div>
      <div className="view_detail_type">{ViewData?.type}</div>
      <div className="view_detail">
        <div className="view_detail_t">Individual Name</div>
        <div className="view_detail_a">{ViewData.name}</div>
      </div>
      <div className="view_detail">
        <div className="view_detail_t">Phone</div>
        <div className="view_detail_a">{ViewData.phone}</div>
      </div>
      <div className="view_detail">
        <div className="view_detail_t">Email</div>
        <div className="view_detail_a">{ViewData.email}</div>
      </div>
      <div className="view_detail">
        <div className="view_detail_t">Gender</div>
        <div className="view_detail_a">{ViewData.gender}</div>
      </div>
      <div className="view_detail">
        <div className="view_detail_t">Birthdate</div>
        <div className="view_detail_a">
          {date_formatter(ViewData?.dob, "date/month/year_long")}
        </div>
      </div>
      <div className="view_detail">
        <div className="view_detail_t">Address</div>
        <div className="view_detail_a">
          {ViewData.state} {ViewData.city} {ViewData.zipcode}
        </div>
      </div>
      <div className="view_detail">
        <div className="view_detail_t">
          When did the individual join ACC=SS?
        </div>
        <div className="view_detail_a">
          {date_formatter(
            new Date(ViewData?.createdAt),
            "date/month/year_long"
          )}
        </div>
      </div>
      <div className="view_detail">
        <div className="view_detail_t">Is your profile public or private?</div>
        <div className="view_detail_a">
          {ViewData?.showAllData ? "Public" : "Private"}
        </div>
      </div>
      <div className="view_detail">
        <div className="view_detail_t">Do you have a criminal background?</div>
        <div className="view_detail_a">
          {ViewData.criminalBackground ? "Yes" : "No"}
        </div>
      </div>
      <div className="view_detail">
        <div className="view_detail_t">
          {" "}
          Are you currently on parole/probation?
        </div>
        <div className="view_detail_a">
          {ViewData.paroleProbation ? "Yes" : "No"}
        </div>
      </div>
      <div className="view_detail">
        <div className="view_detail_t">Are you registered to vote?</div>
        <div className="view_detail_a">
          {ViewData.registeredToVote ? "Yes" : "No"}
        </div>
      </div>
      <div className="view_detail">
        <div className="view_detail_t">
          {" "}
          Have you been pregnant and incarcerated?
        </div>
        <div className="view_detail_a">
          {ViewData.pregnantOrIncarcerated ? "Yes" : "No"}
        </div>
      </div>
      <div className="view_detail">
        <div className="view_detail_t">
          Have you ever been in solitary confinement?
        </div>
        <div className="view_detail_a">
          {ViewData.solitaryConfinement ? "Yes" : "No"}
        </div>
      </div>
      {ViewData?.solitaryConfinement ? (
        <div className="view_detail">
          <div className="view_detail_t">
            Enter the amount of time you have spent in confinement
          </div>
          <div className="view_detail_a">
            {ViewData?.solitaryConfinementExtra?.number
              ? ViewData?.solitaryConfinementExtra?.number +
                " " +
                ViewData?.solitaryConfinementExtra?.duration
              : "-"}
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="view_detail">
        <div className="view_detail_t">Are you a veteran?</div>
        <div className="view_detail_a">{ViewData.veteran ? "Yes" : "No"}</div>
      </div>

      {ViewData.veteran ? (
        <div className="view_detail">
          <div className="view_detail_t">What branch were you a member of?</div>
          <div className="view_detail_a">{ViewData.veteranPlace || "-"}</div>
        </div>
      ) : (
        ""
      )}

      <div className="view_detail">
        <div className="view_detail_t">What services are you seeking?</div>
        <div className="view_detail_a">
          {ViewData?.servicesSeeking?.map((el, i) => (
            <div className="chip_box" key={i}>
              {el}
            </div>
          ))}
        </div>
      </div>
      <div className="view_detail">
        <div className="view_detail_t">Life Experience</div>
        <div className="view_detail_a">{ViewData?.lifeExperience}</div>
      </div>
    </div>
  );
};
