import React from "react";

export const UIndividualViewType = ({
  Page,
  setPage,
  Lists,
  setLists,
  View,
  setView,
}) => {
  return (
    <div className="uindi_view_section">
      <img src="https://imgur.com/O8TrUKL.jpeg" />
      <div className="uindi_views_title">{View.name}</div>
      <div className="uindi_views_type">Individual</div>
      <div className="uindi_views_title1">About us</div>
      <div className="uindi_views_desc">
        Sorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate
        libero et velit interdum, ac aliquet odio mattis.
      </div>
      <div className="uindi_views_emp">
        <div
          className="uindi_views_emp_title"
          onClick={() => {
            setPage("orgviews");
          }}
        >
          My Organization
        </div>
        <div
          onClick={() => {
            setPage("orgviews");
          }}
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.10265 13.5435H20.4019L16.9727 16.9686C15.9885 17.9529 17.4649 19.4332 18.4491 18.449L21.5712 15.323L23.6578 13.2364C23.8525 13.0408 23.9618 12.7761 23.9618 12.5001C23.9618 12.2242 23.8525 11.9595 23.6578 11.7639L18.4452 6.55133C18.3469 6.45056 18.2293 6.37071 18.0994 6.31658C17.9695 6.26246 17.83 6.23517 17.6893 6.23637C17.4814 6.23691 17.2785 6.29952 17.1064 6.41618C16.9344 6.53283 16.8012 6.6982 16.7238 6.8911C16.6463 7.08399 16.6283 7.29561 16.672 7.49882C16.7156 7.70203 16.819 7.88757 16.9688 8.03164L20.4058 11.4568H2.04753C0.602653 11.5316 0.708952 13.6143 2.10265 13.5435Z"
              fill="#D0D0D1"
            />
          </svg>
        </div>
      </div>
      <div className="uindi_views_title1">Organization website</div>
      <div className="uindi_views_desc">Mondaysolutions.com</div>
      <div className="uindi_views_title1">Area of Interests</div>
      <div className="uindi_views_list">
        {View?.serviceoffer?.map((el, i) => (
          <div key={i}>{el}</div>
        ))}
      </div>
      <div className="uindi_views_btns">Start Chatting</div>
    </div>
  );
};
