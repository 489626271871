import React, { useEffect } from "react";
import { Chats } from "../../../component/Message/Chats";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { CreateChat, token } from "../../../component/Api/api";
import { useSelector } from "react-redux";

export const UOrganizationMessage = ({
  Page,
  setPage,
  Lists,
  setLists,
  View,
  setView,
}) => {
  const store = useSelector((state) => state.checktoken_data);
  const nav = useNavigate();
  const [ChatData, setChatData] = useState({});
  const [OpenChat, setOpenChat] = useState(false);
  useEffect(() => {
    CreateChat(
      {
        sender: store.userdetail.id,
        receiver: View.id,
      },
      token,
      ({ status, response }) => {
        if (response.status) {
          setChatData(response?.chat?.chat);
          setOpenChat(true);
        }
      }
    );
  }, [View.id]);
  return OpenChat ? (
    <>
      <Chats
        {...{
          user_data: store.userdetail,
          ChatData,
          OpenChats: OpenChat,
          onbackclick: () => {
            setPage("orgviews");
          },
        }}
      />
    </>
  ) : (
    ""
  );
};
